import { IMarketContainer } from './../models/markets';
import { IDropdownResponse, IResponse } from './../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';
import { IMarket } from '../models/markets';


const baseUrl = '/markets';

export const getStates = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/states`);
    return data;
  } catch (error) {
    logError(error, 'getStates failed');
    return Promise.reject(error);
  }
};

export const getDVMMarkets = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/dvmMarkets`);
    return data;
  } catch (error) {
    logError(error, 'getDVMMarkets failed');
    return Promise.reject(error);
  }
};

export const getMarkets = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  nameSearchTerm?: string;
}): Promise<IResponse<IMarket[]>> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}`, filters);
    return data;
  } catch (error) {
    logError(error, 'getMarkets failed');
    return Promise.reject(error);
  }
};
export const getMarket = async (marketId: number): Promise<IMarket> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${marketId}`);
    return data;
  } catch (error) {
    logError(error, 'getMarket failed');
    return Promise.reject(error);
  }
};

export const createMarket = async (market: IMarketContainer): Promise<string> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, market);
    return data;
  } catch (error) {
    logError({error, payload: market}, 'createMarket failed');
    return Promise.reject(error);
  }
};

export const updateMarket = async (market: IMarketContainer): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}`, market);
    return data;
  } catch (error) {
    logError({error, payload: market}, 'updateMarket failed');
    return Promise.reject(error);
  }
};
export const deleteMarket = async (marketId: number): Promise<IMarket> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${marketId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteMarket failed');
    return Promise.reject(error);
  }
};
