import { Dispatch, FC, SetStateAction, useContext, useEffect, useState } from 'react';
import { IDropdownResponse } from '../../models/util';
import { ReliefSchedulingMarket } from './relief-scheduling-market';
import { Box, Button, ButtonGroup } from '@mui/material';
import { RSSMarketRepeaterContext } from '../../context/rss-market-repeater';
import { Loader } from '../../components';
import { useSnackbar } from 'notistack';
import { putRSSPersist } from '../../fetch';
import { ISchedulePayload } from '../../models/schedule';

export interface IReliefSchedulingRepeater {
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  locations?: IDropdownResponse[];
  setLocations: Dispatch<SetStateAction<IDropdownResponse[] | undefined>>;
  areLocationsLoading: boolean;
  setAreLocationsLoading: Dispatch<SetStateAction<boolean>>;
  startDate: Date;
  endDate: Date;
  showInactiveRequests: boolean;
}

export const ReliefSchedulingRepeater: FC<IReliefSchedulingRepeater> = ({
  selectedDate,
  setSelectedDate,
  locations,
  setLocations,
  areLocationsLoading,
  setAreLocationsLoading,
  startDate,
  endDate,
  showInactiveRequests,
}) => {
  type IReliefSchedulingSelectedMarkets = Array<string | null>;
  const [selectedMarkets, setSelectedMarkets] = useState<IReliefSchedulingSelectedMarkets>([null]);

  const { enqueueSnackbar } = useSnackbar();

  const saveMarketsToBrowserStorage = () => {
    localStorage.setItem('selectedMarkets', JSON.stringify(selectedMarkets));
  };
  const retrieveMarketsFromBrowserStorage = () => {
    const markets = localStorage.getItem('selectedMarkets');
    if (markets) {
      setSelectedMarkets(JSON.parse(markets));
    }
  };

  useEffect(() => {
    retrieveMarketsFromBrowserStorage();
  }, []);

  const addMarket = (marketId: string | null) => {
    setSelectedMarkets(prev => [...prev, marketId]);
  };

  useEffect(() => {
    saveMarketsToBrowserStorage();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarkets]);

  const { isLoading, payloads, setPayloads } = useContext(RSSMarketRepeaterContext);
  const removeMarket = (marketIndex: number) => {
    setSelectedMarkets(markets => {
      markets.splice(marketIndex, 1);
      return [...markets];
    });
    setPayloads(payloads => {
      payloads.splice(marketIndex, 1);
      return [...payloads];
    });
  };

  const [isSubmitting, setIsSubmitting] = useState(false);

  // Instead of calling each market to update it's date after saving,
  // we just reset the markets at the parent level,
  // which will cause each market to re-fetch it's data.
  const resetMarkets = () => {
    setSelectedMarkets(prev => prev);
    setIsSubmitting(false);
  };

  const isPayloadEmpty = (payload: ISchedulePayload) => {
    const isEmpty =
      payload.scheduledShiftCreation.length === 0 &&
      payload.scheduledShiftDeletion.length === 0 &&
      payload.shiftRequestApproval.length === 0 &&
      payload.shiftRequestRejection.length === 0 &&
      payload.shiftRequestsToReinstate.length === 0;

    return isEmpty;
  };
  const saveAll = () => {
    setIsSubmitting(true);
    const filteredPayloads = payloads.filter(payload => {
      return !isPayloadEmpty(payload);
    });
    Promise.all(
      filteredPayloads.map(async payload => {
        try {
          const res = await putRSSPersist(payload);

          if (typeof res === 'string') {
            enqueueSnackbar(`${res}`, {
              variant: 'error',
            });
          }

          // res is successful
          if (res.status >= 200 && res.status <= 299) {
            enqueueSnackbar(`${payload?.locationName} was saved!`, {
              variant: 'success',
            });
            await payload.reloadMarketSchedule();
          }

          if (res.Type === 'Error') {
            enqueueSnackbar(`${payload?.locationName} ${res?.Detail}`, {
              variant: 'error',
            });
          }
        } catch (error: any) {
          console.log('error', error);
          const errorMessage = `${payload?.locationName} ${error?.response?.data?.Detail}`;
          enqueueSnackbar(errorMessage || `${error}`, {
            variant: 'error',
          });
          console.log(error);
        }
      })
    )
      .catch(err => {
        console.log('err', err);
        enqueueSnackbar(`${err?.Detail ?? 'Something went wrong, please try saving again.'}`, {
          variant: 'error',
        });
      })
      .finally(() => {
        resetMarkets();
      });
  };

  return (
    <>
      {(isLoading || isSubmitting) && <Loader position="centered" type="fullscreen" />}
      {selectedMarkets.map((market, index) => (
        <ReliefSchedulingMarket
          key={market ?? index}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          locations={locations}
          setLocations={setLocations}
          areLocationsLoading={areLocationsLoading}
          setAreLocationsLoading={setAreLocationsLoading}
          startDate={startDate}
          endDate={endDate}
          preSelectedMarket={market}
          selectedMarketIndex={index}
          selectedMarkets={selectedMarkets}
          setSelectedMarkets={setSelectedMarkets}
          removeMarket={() => removeMarket(index)}
          showSaveButton={false}
          showInactiveRequests={showInactiveRequests}
        />
      ))}
      <Box display={'flex'} flexDirection={'row'} margin={'1rem 0'} padding={'1rem 0'}>
        <ButtonGroup>
          <Button variant="contained" onClick={() => addMarket(null)}>
            Add Market
          </Button>
          <Button variant="contained" onClick={saveAll}>
            Save Markets
          </Button>
        </ButtonGroup>
      </Box>
    </>
  );
};
