import { AppBar, Button, Grid, Tab, Tabs, useMediaQuery } from '@mui/material';
import { FC, useEffect, useState, useContext } from 'react';
import { Loader, Page } from '../../components';
import { AddBusiness, Place, Store } from '@mui/icons-material';
import { Link } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { UserContext } from '../../context';
import { LocationsTable } from './location-table';
import { MarketsTable } from './markets-table';
import { IMarket } from '../../models/markets';
import { MarketFilters } from './market-filters';
import { getLocationTypes } from '../../fetch/lookups';
import { IDropdownResponse } from '../../models/util';
import { useSnackbar } from 'notistack';
import { getDVMMarkets } from '../../fetch';
import { LocationsFilters } from './locations-filters';

enum RegionTabs {
  Locations,
  Markets,
}

export const Locations: FC = () => {
  const { isPracticeManager, isSysAdmin, isRSS } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isDeleting, setIsDeleting] = useState(false);
  const [page, setPage] = useState(0);

  const isMobile = useMediaQuery('(max-width: 960px)');

  const [selectedTab, setSelectedTab] = useState<RegionTabs>(RegionTabs.Locations);

  const handleTabChangeWithoutSave = (value: number) => {
    setSelectedTab(value);
  };

  const classes = useStyles();

  const [isMarketModalOpen, setIsMarketModalOpen] = useState(false);
  const [currentMarket, setCurrentMarket] = useState<IMarket | undefined>(undefined);

  const handleAddMarket = () => {
    setIsMarketModalOpen(true);
  };
  const handleEditMarket = (market: IMarket) => {
    setCurrentMarket(market);
    setIsMarketModalOpen(true);
  };

  const handleCloseMarketModal = () => {
    setCurrentMarket(undefined);
    setIsMarketModalOpen(false);
  };

  // Search functionality
  const [searchValue, setSearchValue] = useState<string>('');
  const [hasAppliedFilters, setHasAppliedFilters] = useState<boolean>(false);

  // Search functionality
  const [marketSearchValue, setMarketSearchValue] = useState<string>('');
  const [hasAppliedMarketFilters, setHasAppliedMarketFilters] = useState<boolean>(false);

  // Filters
  const { enqueueSnackbar } = useSnackbar();
  const [selectedLocationType, setSelectedDvmType] = useState('');
  const [selectedMarkets, setSelectedMarkets] = useState<IDropdownResponse[]>([]);

  const [locationTypes, setLocationTypes] = useState<IDropdownResponse[]>([]);
  const [areLocationTypesLoading, setLocationTypesLoading] = useState(false);
  const loadLocationTypes = async () => {
    setLocationTypesLoading(true);
    try {
      const res = await getLocationTypes();
      setLocationTypes(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading location types, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setLocationTypesLoading(false);
    }
  };

  const [areMarketsLoading, setAreMarketsLoading] = useState(true);
  const [markets, setMarkets] = useState<IDropdownResponse[]>([]);

  const fetchMarkets = async () => {
    setAreMarketsLoading(true);
    try {
      const res = await getDVMMarkets();
      setMarkets(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreMarketsLoading(false);
    }
  };
  const [filters, setFilters] = useState<{
    locationType?: string;
    marketIds?: string[] | number[];
  }>({
    locationType: undefined,
    marketIds: selectedMarkets?.map(s => s.value),
  });

  // visibility condition based on roles
  const canViewRoles =  !isPracticeManager || isSysAdmin || isRSS;

  useEffect(() => {
    fetchMarkets();
    loadLocationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setMarketSearchValue('');
    setSearchValue('');
    setPage(0);
    setFilters({});
    setSelectedMarkets([]);
    setSelectedDvmType('');
    setHasAppliedFilters(false);
    setHasAppliedMarketFilters(false);
  }, [selectedTab]);

  return (
    <Page
      title={'Locations'}
      paddingBottom={24}
      additionalHeaderContent={
        <>
          {selectedTab === RegionTabs.Locations && canViewRoles && (
            <Button
              startIcon={<Place />}
              color="primary"
              variant="contained"
              disabled={isLoading}
              component={Link}
              to="/locations/new-location"
            >
              New Location
            </Button>
          )}

          {selectedTab === RegionTabs.Markets && (
            <Button
              startIcon={<AddBusiness />}
              color="primary"
              variant="contained"
              disabled={isLoading}
              onClick={() => handleAddMarket()}
            >
              New Market
            </Button>
          )}
        </>
      }
    >
      {(isDeleting || isLoading) && (
        <Loader type="fullscreen" position="centered" title="Loading..." />
      )}
      <Grid container spacing={3}>
        <Grid item xs={12} className={classes.tabBar}>
          <AppBar
            classes={{ root: classes.tabBarRoot }}
            position="static"
            color="default"
            component="div"
          >
            <Tabs
              value={selectedTab}
              indicatorColor="primary"
              textColor="primary"
              onChange={(_, value) => {
                handleTabChangeWithoutSave(value);
              }}
              variant={isMobile ? 'scrollable' : 'standard'}
            >
              <Tab
                icon={<Place />}
                iconPosition={isMobile ? 'top' : 'start'}
                className={classes.regionTabs}
                id="locations"
                aria-controls="locations"
                label={'Locations'}
              />
              {canViewRoles && (
                <Tab
                  icon={<Store />}
                  iconPosition={isMobile ? 'top' : 'start'}
                  className={classes.regionTabs}
                  id="markets"
                  aria-controls="markets"
                  label={'Markets'}
                />
              )}
            </Tabs>
          </AppBar>
        </Grid>
        <Grid item xs={12}>
          {selectedTab === RegionTabs.Markets && (
            <MarketFilters
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              applyFilters={(clearFilters?: boolean) => {
                if (clearFilters) {
                  setMarketSearchValue('');
                }
              }}
              setHasAppliedFilters={setHasAppliedMarketFilters}
              handleSearch={(val: string) => {
                setMarketSearchValue(val);
              }}
              isLoading={isLoading}
              hasAppliedFilters={hasAppliedMarketFilters}
            />
          )}
          {selectedTab === RegionTabs.Locations && (
            <LocationsFilters
              setSearchValue={setSearchValue}
              searchValue={searchValue}
              applyFilters={(clearFilters?: boolean) => {
                if (clearFilters) {
                  setFilters({});
                  setSearchValue('');
                } else {
                  setPage(0);
                  setFilters({
                    ...filters,
                    marketIds: selectedMarkets?.map(s => s.value) || undefined,
                    locationType: selectedLocationType || undefined,
                  });
                }
              }}
              setHasAppliedFilters={setHasAppliedFilters}
              handleSearch={(val: string) => {
                setPage(0);
                setSearchValue(val);
              }}
              isLoading={isLoading || areLocationTypesLoading || areMarketsLoading}
              types={locationTypes}
              selectedType={selectedLocationType}
              setSelectedType={setSelectedDvmType}
              hasAppliedFilters={hasAppliedFilters}
              markets={markets ?? []}
              selectedMarkets={selectedMarkets}
              setSelectedMarkets={setSelectedMarkets}
            />
          )}
        </Grid>
        <Grid item xs={12}>
          {selectedTab === RegionTabs.Locations && (
            <LocationsTable
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsDeleting={setIsDeleting}
              page={page}
              setPage={setPage}
              searchValue={searchValue}
              filters={filters}
            />
          )}
          {selectedTab === RegionTabs.Markets && (
            <MarketsTable
              isLoading={isLoading}
              setIsLoading={setIsLoading}
              setIsDeleting={setIsDeleting}
              handleEditMarket={handleEditMarket}
              currentMarket={currentMarket}
              isMarketModalOpen={isMarketModalOpen}
              handleCloseMarketModal={handleCloseMarketModal}
              searchValue={marketSearchValue}
            />
          )}
        </Grid>
      </Grid>
    </Page>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  tabBar: {
    position: 'sticky',
    top: 0,
    zIndex: 2,
    width: '100vw',
    marginLeft: '-24px',
    paddingTop: '0!important',
    [theme.breakpoints.down('mobile')]: {
      width: '100vw',
    },
  },
  tabBarRoot: {
    width: '100vw',
  },
  regionTabs: {
    fontSize: 14,
    borderRadius: 0,
  },
}));
