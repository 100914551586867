import { FC, useState } from 'react';
import clsx from 'clsx';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { Grid, Button, Divider, TextField } from '@mui/material';
import { FilterList, Close, ArrowDropDown, ArrowDropUp } from '@mui/icons-material';

interface IMarketFilters {
  isLoading: boolean;
  applyFilters: (clearFilters?: boolean) => void;
  setSearchValue: (val: string) => void;
  searchValue: string;
  handleSearch: (val: string) => void;
  hasAppliedFilters: boolean;
  setHasAppliedFilters: (val: boolean) => void;
}

export const MarketFilters: FC<IMarketFilters> = ({
  isLoading,
  applyFilters,
  handleSearch,
  hasAppliedFilters,
  setHasAppliedFilters,
}) => {
  const [isMobileFilterButtonOpen, setIsMobileFilterButtonOpen] = useState<boolean>(false);
  const classes = useStyles({ isMobileFilterButtonOpen });

  const [searchValue, setSearchValue] = useState<string>('');
  const noFilters = !searchValue;
  return (
    <>
      <Grid container spacing={1} alignItems="center" className={classes.searchWrapper}></Grid>
      <Button
        color="secondary"
        variant="contained"
        className={classes.mobileButton}
        startIcon={<FilterList />}
        endIcon={isMobileFilterButtonOpen ? <ArrowDropUp /> : <ArrowDropDown />}
        onClick={() => {
          setIsMobileFilterButtonOpen(!isMobileFilterButtonOpen);
        }}
      >
        Filters
      </Button>
      {!isMobileFilterButtonOpen && <Divider className={classes.divider} />}
      <Grid container spacing={1} alignItems="center" className={classes.wrapper}>
        <Grid item xs={12} sm={6} md={3}>
          <TextField
            fullWidth
            variant="standard"
            autoComplete="nope"
            label="Search"
            name="search"
            classes={{ root: classes.searchRoot }}
            value={searchValue}
            size="small"
            onKeyDown={e => {
              if (e.key === 'Enter') {
                setHasAppliedFilters(true);
                applyFilters();
                handleSearch(searchValue);
              }
            }}
            onChange={e => {
              setSearchValue(e.target.value);
            }}
          />
        </Grid>

        <Grid item xs={12} sm={6} lg={4}>
          <Button
            color="primary"
            variant="contained"
            className={classes.button}
            disabled={isLoading}
            startIcon={<FilterList />}
            onClick={() => {
              if (noFilters) {
                setHasAppliedFilters(false);
                applyFilters();
              } else {
                setHasAppliedFilters(true);
                applyFilters();
                handleSearch(searchValue);
              }
            }}
          >
            Apply Filters
          </Button>
          {hasAppliedFilters && (
            <Button
              className={clsx(classes.button, classes.resetButton)}
              variant="contained"
              color="inherit"
              disabled={isLoading}
              startIcon={<Close />}
              onClick={() => {
                setSearchValue('');
                setHasAppliedFilters(false);
                applyFilters(true);
              }}
            >
              Reset
            </Button>
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { isMobileFilterButtonOpen?: boolean }>((theme: Theme) => ({
  mobileButton: {
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  button: {
    marginTop: 4,
    marginBottom: 4,
    height: 40,
    textTransform: 'capitalize',
    width: '100%',

    [theme.breakpoints.up('sm')]: {
      width: 'auto',
      marginLeft: theme.spacing(2),
    },
  },
  resetButton: {
    '@media (min-width: 600px)': {
      marginLeft: 11,
    },
    [theme.breakpoints.up('sm')]: {
      marginLeft: theme.spacing(1),
    },
  },
  wrapper: ({ isMobileFilterButtonOpen }) =>
    isMobileFilterButtonOpen
      ? {
          marginTop: 10,
          marginBottom: 10,
          display: 'flex',
        }
      : {
          display: 'none',
          marginBottom: theme.spacing(1),
          [theme.breakpoints.up('sm')]: {
            display: 'flex',
          },
        },
  divider: {
    display: 'block',
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  gridItem: {
    padding: `3px 8px !important`,
    [theme.breakpoints.up('sm')]: {
      padding: `8px !important`,
    },
  },
  searchIcon: {
    cursor: 'pointer',
    color: theme.palette.grey[500],
  },
  ellipsis: {
    fontSize: 14,
    width: 'auto',
    whiteSpace: 'normal',
    overflow: 'visible',
    '@media (min-width: 960px)': {
      textOverflow: 'ellipsis',
      whiteSpace: 'nowrap',
      overflow: 'hidden',
    },
    '@media (min-width: 1400px)': {
      width: 'auto',
      whiteSpace: 'normal',
      overflow: 'visible',
    },
  },
  formControlRoot: {
    marginTop: 0,
  },
  textFieldRoot: {
    marginBottom: 0,
  },
  searchWrapper: {
    marginBottom: theme.spacing(1),
    [theme.breakpoints.up('sm')]: {
      display: 'flex',
      marginBottom: theme.spacing(1),
    },
  },
  clearSearchButton: {
    '@media (min-width: 408px)': {
      marginTop: 3,
      marginLeft: theme.spacing(1),
    },
  },
  searchButton: {
    minWidth: '8rem',
  },
  searchRoot: {
    marginTop: 0,
  },
}));
