import {
  ICreateConfiguredShift,
  IConfiguredShift,
  IUpdateConfiguredShift,
} from '../models/configured-shifts';
import { IResponse } from '../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/ConfiguredShifts';

export const getConfiguredShifts = async (
  locationId: string,
  filters?: { page?: number; perPage?: number; sortBy?: string; sortDirection?: string }
): Promise<IResponse<IConfiguredShift[]>> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/paged/${locationId}`, filters);
    return data;
  } catch (error) {
    logError(error, 'getConfiguredShifts failed');
    return Promise.reject(error);
  }
};

export const createConfiguredShift = async (shift: ICreateConfiguredShift): Promise<string> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, { shift });
    return data;
  } catch (error: any) {
    logError({ error, payload: { shift } }, 'createIncentiveRate failed');
    return Promise.reject(error);
  }
};

export const updateConfiguredShift = async (shift: IUpdateConfiguredShift): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}`, { shift });
    return data;
  } catch (error: any) {
    logError({ error, payload: { shift } }, 'updateConfiguredShift failed');
    return Promise.reject(error);
  }
};

export const deleteConfiguredShift = async (configuredShiftId: number): Promise<any> => {
  try {
    const data = await authFetch.delete(`${baseUrl}/${configuredShiftId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteConfiguredShift failed');
    return Promise.reject(error);
  }
};
