import { Box, Button, FormControl, Grid, InputLabel, MenuItem, Select } from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { deepEqual } from 'fast-equals';
import { useSnackbar } from 'notistack';
import { Dispatch, FC, SetStateAction, useCallback, useContext, useEffect, useState } from 'react';
import { CalendarGrid } from '../../components/calendar-grid';
import { IShiftRequestApproval, RSSMarketRepeaterContext } from '../../context/rss-market-repeater';
import { getMarketSchedule, putRSSPersist } from '../../fetch';
import { IMarketCalendarLocation, IMarketCalendarShiftRequest } from '../../models/schedule';
import { IDropdownResponse } from '../../models/util';

export interface IReliefSchedulingMarket {
  preSelectedMarket: string | null;
  selectedDate: Date;
  setSelectedDate: Dispatch<SetStateAction<Date>>;
  locations?: IDropdownResponse[];
  setLocations: Dispatch<SetStateAction<IDropdownResponse[] | undefined>>;
  areLocationsLoading: boolean;
  setAreLocationsLoading: Dispatch<SetStateAction<boolean>>;
  startDate: Date;
  endDate: Date;
  selectedMarketIndex: number;
  selectedMarkets: Array<string | null>;
  setSelectedMarkets: Dispatch<SetStateAction<Array<string | null>>>;
  removeMarket: () => void;
  showSaveButton?: boolean;
  showInactiveRequests?: boolean;
  inactiveRequests?: boolean;
}
export const ReliefSchedulingMarket: FC<IReliefSchedulingMarket> = ({
  preSelectedMarket,
  selectedDate,
  setSelectedDate,
  locations,
  setLocations,
  areLocationsLoading,
  setAreLocationsLoading,
  startDate,
  endDate,
  selectedMarketIndex,
  selectedMarkets,
  setSelectedMarkets,
  removeMarket,
  showSaveButton = true,
  showInactiveRequests,
  inactiveRequests,
}) => {
  const { setIsLoading, setPayloads, setAllPayloads } = useContext(RSSMarketRepeaterContext);
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const handleChangeWithoutSave = () => {
    if (
      checkedRequests.length > 0 ||
      rejectedRequests.length > 0 ||
      reinstatedRequests.length > 0 ||
      !deepEqual(scheduledMarketLocations, scheduledMarketLocationsClone)
    ) {
      const result = window.confirm('You have unsaved changes, are you sure you want to continue?');
      if (result) {
        setScheduledMarketLocations([]);
        setCheckedRequests([]);
        setReinstatedRequests([]);
        setRejectedRequests([]);
        return result;
      } else {
        return result;
      }
    } else {
      return true;
    }
  };

  // Dropdowns
  const [selectedMarket, setSelectedMarket] = useState<string | null>(preSelectedMarket);

  // Schedule
  const [scheduledMarketLocations, setScheduledMarketLocations] = useState<
    IMarketCalendarLocation[]
  >([]);
  const [scheduledMarketLocationsClone, setScheduledMarketLocationsClone] = useState<
    IMarketCalendarLocation[]
  >([]);

  const fetchSchedule = async () => {
    try {
      setIsLoading(true);
      // const mappedStatuses = selectedLocationTypes?.map(s => s.description);
      const res = await getMarketSchedule({
        marketIds: parseInt(selectedMarket as string) ?? undefined,
        dateFrom: startDate,
        dateTo: endDate,
        IncludeInactiveShifts: !!showInactiveRequests,
        locationTypes: ['UrgentVet'],
      });

      setScheduledMarketLocations(res?.[0]?.locationsAndShifts ?? []);
      setScheduledMarketLocationsClone(
        JSON.parse(JSON.stringify(res?.[0]?.locationsAndShifts ?? []))
      );
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading the Schedule, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDateClick = (date: Date) => {};

  const handleRemoveClick = (date: Date, locationId: string | number | undefined) => {
    setScheduledMarketLocations((prev: IMarketCalendarLocation[]) => {
      const selected = prev
        .find(location => location.locationId === locationId)
        ?.shifts.find(shift => {
          return new Date(shift.shiftDate).getTime() === new Date(date).getTime();
        });

      if (selected) {
        selected.isDeleted = true;
      }
      return [...prev];
    });
  };
  const [checkedRequests, setCheckedRequests] = useState<IMarketCalendarShiftRequest[]>([]);
  const handleApprove = (request: IMarketCalendarShiftRequest) => {
    setCheckedRequests(prev => {
      return prev.some(
        (req: IMarketCalendarShiftRequest) => req.shiftRequestId === request.shiftRequestId
      )
        ? prev.filter(
            (req: IMarketCalendarShiftRequest) => req.shiftRequestId !== request.shiftRequestId
          )
        : [...prev, request];
    });

    setAllPayloads((prev: IShiftRequestApproval) => {
      if (
        prev?.shiftRequestApproval?.requests?.find(
          (aPReq: any) => aPReq.shiftId === request.shiftRequestId
        )
      ) {
        return {
          ...prev,
          shiftRequestApproval: {
            requests: prev?.shiftRequestApproval?.requests?.filter(
              (aPReq: any) => aPReq.shiftId !== request.shiftRequestId
            ),
          },
        };
      } else {
        return {
          ...prev,
          shiftRequestApproval: {
            requests: Array.from(
              new Set([
                ...(prev?.shiftRequestApproval?.requests ?? []),
                {
                  shiftId: request.shiftRequestId,
                  dvmId: request.dvmId,
                  locationId: request.locationId,
                  date: request.shiftDate,
                },
              ])
            ),
          },
        };
      }
    });
  };

  const [rejectedRequests, setRejectedRequests] = useState<IMarketCalendarShiftRequest[]>([]);
  const [reinstatedRequests, setReinstatedRequests] = useState<IMarketCalendarShiftRequest[]>([]);
  const handleReinstate = (request: IMarketCalendarShiftRequest) => {
    if (request.shiftRequestId !== null) {
      setReinstatedRequests(prev => {
        return prev.some(
          (req: IMarketCalendarShiftRequest) => req.shiftRequestId === request.shiftRequestId
        )
          ? prev.filter(
              (req: IMarketCalendarShiftRequest) => req.shiftRequestId !== request.shiftRequestId
            )
          : [...prev, request];
      });
    }
  };
  const handleReject = (request: IMarketCalendarShiftRequest) => {
    if (request.shiftRequestId !== null) {
      setRejectedRequests(prev => {
        return prev.some(
          (req: IMarketCalendarShiftRequest) => req.shiftRequestId === request.shiftRequestId
        )
          ? prev.filter(
              (req: IMarketCalendarShiftRequest) => req.shiftRequestId !== request.shiftRequestId
            )
          : [...prev, request];
      });
    }
  };

  const isSubmitDisabled = () => {
    let isDisabled = true;
    if (checkedRequests.length >= 1) isDisabled = false;
    if (rejectedRequests.length >= 1) isDisabled = false;
    if (reinstatedRequests.length >= 1) isDisabled = false;
    if (!deepEqual(scheduledMarketLocations, scheduledMarketLocationsClone)) isDisabled = false;
    if (isSubmitting) isDisabled = true;
    return isDisabled;
  };
  const reloadPayload = useCallback(async () => {
    await fetchSchedule();
    setCheckedRequests([]);
    setRejectedRequests([]);
    setReinstatedRequests([]);
    setAllPayloads({ shiftRequestApproval: { requests: [] } });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startDate, selectedDate, showInactiveRequests]);

  const getPayload = useCallback(() => {
    const deletedItems =
      scheduledMarketLocations
        ?.flatMap(marketLocations => marketLocations.shifts)
        ?.filter(shifts => shifts.isDeleted)
        ?.map(shift => shift.shiftId as number) ?? [];

    const payload = {
      // FT doesn't handle shift Request Approvals, so this should always be null
      shiftRequestApproval: checkedRequests.map(shift => shift.shiftRequestId),
      scheduledShiftDeletion: Array.isArray(deletedItems) ? deletedItems : [],
      scheduledShiftCreation: [],
      shiftRequestRejection: rejectedRequests.map(shift => shift.shiftRequestId),
      shiftRequestsToReinstate: reinstatedRequests.map(shift => shift.shiftRequestId),
      locationName:
        locations?.find(location => location.value === selectedMarket)?.description ?? 'No Name',
      reloadMarketSchedule: reloadPayload,
    };

    return payload;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [checkedRequests, rejectedRequests, reinstatedRequests, scheduledMarketLocations]);

  useEffect(() => {
    setPayloads(prev => {
      prev[selectedMarketIndex] = getPayload();
      return [...prev];
    });
  }, [getPayload, selectedMarketIndex, selectedMarkets, inactiveRequests, setPayloads]);

  const [isSubmitting, setIsSubmitting] = useState(false);
  const handleSubmit = async () => {
    setIsSubmitting(true);
    const payload = getPayload();
    try {
      const res = await putRSSPersist(payload);

      if (typeof res === 'string') {
        enqueueSnackbar(`${res}`, {
          variant: 'error',
        });
      }

      if (res.Type === 'Error') {
        enqueueSnackbar(`${res?.Detail}`, {
          variant: 'error',
        });
      }

      // res is successful
      if (res.status >= 200 && res.status <= 299) {
        await fetchSchedule();
        setCheckedRequests([]);
        setReinstatedRequests([]);
        setRejectedRequests([]);
      }
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `${error}`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  useEffect(() => {
    if (selectedMarket && startDate && endDate) {
      fetchSchedule();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedMarket, startDate, endDate, showInactiveRequests]);

  const locationsWithoutSelectedMarkets = locations?.filter(location => {
    if (location.value === preSelectedMarket) {
      return true;
    } else {
      return !selectedMarkets.includes(location.value);
    }
  });

  return (
    <Box width="100%">
      <Grid container spacing={1}>
        <Grid item xs={3}>
          <FormControl fullWidth required={true} variant="standard" size="small">
            <InputLabel shrink={!!selectedMarket} htmlFor="locationsLabel">
              Market
            </InputLabel>
            <Select
              fullWidth
              name="locations"
              labelId="locationsLabel"
              id="locations"
              disabled={areLocationsLoading}
              value={selectedMarket}
              onChange={e => {
                handleChangeWithoutSave() && setSelectedMarket(e.target.value as string);
                setSelectedMarkets(prev => {
                  prev[selectedMarketIndex] = e.target.value as string;
                  return [...prev];
                });
              }}
            >
              {locationsWithoutSelectedMarkets?.map((location: IDropdownResponse, index) => {
                return (
                  <MenuItem key={`${index + 1}`} value={location.value}>
                    {location.description}
                  </MenuItem>
                );
              })}
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      {locations && locations.length > 0 && !!selectedMarket && scheduledMarketLocations && (
        <CalendarGrid
          key={selectedMarketIndex}
          selectedLocation={selectedMarket}
          locations={locations}
          selectedDate={selectedDate}
          setSelectedDate={setSelectedDate}
          handleDateClick={handleDateClick}
          handleRemoveClick={handleRemoveClick}
          scheduled={scheduledMarketLocations}
          isMarket={true}
          allowDvmCardDelete={true}
          handleApprove={handleApprove}
          handleReject={handleReject}
          handleReinstate={handleReinstate}
          checkedRequests={checkedRequests}
          reinstatedRequests={reinstatedRequests}
          rejectedRequests={rejectedRequests}
          fetchSchedule={() => fetchSchedule()}
          reloadPayload={reloadPayload}
          showInactiveRequests={showInactiveRequests}
        />
      )}
      {showSaveButton && scheduledMarketLocations.length !== 0 && (
        <Box display={'flex'} flexDirection={'row-reverse'}>
          <Button
            variant="contained"
            className={classes.saveButton}
            disabled={isSubmitDisabled()}
            // disabled={true}
            onClick={() => handleSubmit()}
          >
            Save
          </Button>
        </Box>
      )}
      <Box display={'flex'} flexDirection={'row-reverse'} marginTop={'1rem'}>
        <Button variant="contained" disabled={!isSubmitDisabled()} onClick={() => removeMarket()}>
          Remove Market
        </Button>
      </Box>
    </Box>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  saveButton: {
    marginTop: theme.spacing(1),
  },
  schedPaginationControls: {
    '& .this-week-btn-wrap': {
      paddingTop: theme.spacing(1.75),
    },
  },
  weekPaginationBtns: {
    border: 'none',
    background: 'none',
    color: theme.palette.common.black,
    padding: 0,
    borderRadius: 0,
    minWidth: theme.spacing(4),

    '&:hover': {
      background: 'none',
    },
  },
}));
