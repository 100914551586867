import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import React, { FC, useState, useEffect, useMemo, useContext } from 'react';
import { UserContext } from '../../context';
import { deleteLocation, getLocations } from '../../fetch/locations';
import { ILocation } from '../../models';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn, sortable } from '../../models/util';
import { DeleteForever, EditLocationAlt } from '@mui/icons-material';
import { useHistory } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';

interface ILocationTable {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  page: number;
  setPage: React.Dispatch<React.SetStateAction<number>>;
  searchValue: string;
  filters: {
    locationType?: string;
    marketIds?: string[] | number[];
  };
}
export const LocationsTable: FC<ILocationTable> = ({
  isLoading,
  setIsLoading,
  setIsDeleting,
  page,
  setPage,
  searchValue,
  filters,
}) => {
  const [locations, setLocations] = useState<ILocation[]>([]);

  const { isPracticeManager, isSysAdmin, isRSS } = useContext(UserContext);
  // visibility condition based on roles
  const canViewRoles = isPracticeManager && !isSysAdmin && !isRSS;
  
  const [perPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [selectedSort, setSelectedSort] = useState<string>('1');
  const [sortDirection, setSortDirection] = useState<{
    Name?: sortable;
    Market?: sortable;
    Address?: sortable;
    City?: sortable;
    State?: sortable;
    Zip?: sortable;
    Type?: sortable;
  }>({
    Name: 'Asc',
  });

  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const classes = useStyles();

  const fetchLocations = async () => {
    try {
      setIsLoading(true);
      const res = await getLocations({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
        searchTerm: searchValue,
        ...filters,
      });
      setLocations(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchLocations();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection, filters]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const handleEdit = (locationId: number | string) => {
    history.push(`locations/${locationId}`);
  };

  const handleDelete = async (locationId: number | string) => {
    const result = window.confirm('Are you sure you want to delete this Location?');
    if (result) {
      try {
        setIsDeleting(true);
        await deleteLocation(parseInt(locationId as string));
        await fetchLocations();
        enqueueSnackbar('Location Deleted!', {
          variant: 'success',
        });
      } catch (error: any) {
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting location, please try again.`, {
          variant: 'error',
        });
        console.log(error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === 'Desc',
        handleClickColumn: () => handleClickColumn('Name'),
      },
      {
        Header: 'Market',
        accessor: 'marketName',
        isServerSorted: selectedSort === 'Market',
        isServerSortedDesc: sortDirection.Market === 'Desc',
        handleClickColumn: () => handleClickColumn('Market'),
      },
      {
        Header: 'Type',
        accessor: 'locationTypeName',
        isServerSorted: selectedSort === 'Type',
        isServerSortedDesc: sortDirection.Type === 'Desc',
        handleClickColumn: () => handleClickColumn('Type'),
      },
      {
        Header: 'Address',
        accessor: '',
        isServerSorted: selectedSort === 'Address',
        isServerSortedDesc: sortDirection.Address === 'Desc',
        handleClickColumn: () => handleClickColumn('Address'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return <span>{original.address.street}</span>;
        },
      },
      {
        Header: 'City',
        accessor: 'city',
        isServerSorted: selectedSort === 'City',
        isServerSortedDesc: sortDirection.City === 'Desc',
        handleClickColumn: () => handleClickColumn('City'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return <span>{original.address.city}</span>;
        },
      },
      {
        Header: 'State',
        accessor: 'state',
        isServerSorted: selectedSort === 'State',
        isServerSortedDesc: sortDirection.State === 'Desc',
        handleClickColumn: () => handleClickColumn('State'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return <span>{original.address.state}</span>;
        },
      },
      {
        Header: 'Zip',
        accessor: 'zip',
        isServerSorted: selectedSort === 'Zip',
        isServerSortedDesc: sortDirection.Zip === 'Desc',
        handleClickColumn: () => handleClickColumn('Zip'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return <span>{original.address.zip}</span>;
        },
      },
      {
        Header: 'Accounting Location ID',
        accessor: 'accountsPayableId',
        sort: false,
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return (
            <span>
              {!!original.accountsPayableId ? original.accountsPayableId : 'No ID Provided'}
            </span>
          );
        },
      },

      {
        Header: '',
        accessor: '',
        sort: false,
        id: 'actions',
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: ILocation } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original.locationId);
                }}
              >
                Edit
              </Button>
              {!canViewRoles && (
                <IconButton
                  className={classes.button}
                  onClick={() => handleDelete(original.locationId)}
                >
                  <DeleteForever />
                </IconButton>
              )}
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  return (
    <Table
      columns={columns}
      data={locations}
      isLoading={isLoading}
      serverPage={page}
      serverRecordCount={recordCount}
      serverPerPage={perPage}
      handlePage={setPage}
      handleRowsPerPage={setRowsPerPage}
      ResponsiveComponent={isMobile ? MobileTable : undefined}
      mobileProps={{
        fields: columns
          .filter(col => col.Header)
          .map(col => ({
            name: col.Header,
            accessor: col.accessor,
            Cell: col?.Cell,
          })),
        handleEdit: (location: ILocation) => {
          handleEdit(location.locationId);
        },
        ...(!canViewRoles
          ? {}
          : {
              handleDelete: (location: ILocation) => handleDelete(location.locationId),
            }),
      }}
    />
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
