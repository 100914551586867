import { Box, Button, IconButton, useMediaQuery } from '@mui/material';
import React, { FC, useState, useEffect, useMemo } from 'react';
import { useSnackbar } from 'notistack';
import { Table } from '../../components/table/Table';
import { MobileTable } from '../../components/table/MobileTable';
import { IColumn, IDropdownResponse, sortable } from '../../models/util';
import { DeleteForever, Edit } from '@mui/icons-material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IMarket } from '../../models/markets';
import { deleteMarket, getMarkets } from '../../fetch/markets';
import { getStates } from '../../fetch/lookups';
import { MarketModal } from './add-edit-market-modal';
import { Loader } from '../../components';

interface IMarketsTable {
  isLoading: boolean;
  setIsLoading: React.Dispatch<React.SetStateAction<boolean>>;
  setIsDeleting: React.Dispatch<React.SetStateAction<boolean>>;
  handleEditMarket: (market: IMarket) => void;
  currentMarket: IMarket | undefined;
  isMarketModalOpen: boolean;
  handleCloseMarketModal: () => void;
  searchValue: string;
}
export const MarketsTable: FC<IMarketsTable> = ({
  isLoading,
  setIsLoading,
  setIsDeleting,
  handleEditMarket,
  currentMarket,
  isMarketModalOpen,
  handleCloseMarketModal,
  searchValue,
}) => {
  const [markets, setMarkets] = useState<IMarket[]>([]);
  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(10);
  const [recordCount, setRecordCount] = useState(0);
  const isMobile = useMediaQuery('(max-width: 960px)');
  const [selectedSort, setSelectedSort] = useState<string>('1');
  const [sortDirection, setSortDirection] = useState<{
    Name?: sortable;
    State?: sortable;
  }>({
    Name: 'Asc',
  });

  const { enqueueSnackbar } = useSnackbar();
  const classes = useStyles();

  const [areMarketsLoading, setIsMarketsLoading] = useState(true);
  const fetchMarkets = async () => {
    try {
      setIsMarketsLoading(true);
      const res = await getMarkets({
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
        nameSearchTerm: searchValue,
      });
      setMarkets(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsMarketsLoading(false);
    }
  };

  useEffect(() => {
    fetchMarkets();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection, searchValue]);

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  const handleDelete = async (marketId: number | string) => {
    const result = window.confirm('Are you sure you want to delete this Market?');
    if (result) {
      try {
        setIsDeleting(true);
        await deleteMarket(parseInt(marketId as string));
        await fetchMarkets();
        enqueueSnackbar('Market Deleted!', {
          variant: 'success',
        });
      } catch (error: any) {
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting market, please try again.`, {
          variant: 'error',
        });
        console.log(error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const [areStatesLoading, setAreStatesLoading] = useState(true);
  const [states, setStates] = useState<IDropdownResponse[]>([]);
  const fetchStates = async () => {
    setAreStatesLoading(true);
    try {
      setIsLoading(true);
      const res = await getStates();
      setStates(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading states, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreStatesLoading(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Values for Dropdowns
    fetchStates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Name',
        accessor: 'name',
        isServerSorted: selectedSort === 'Name',
        isServerSortedDesc: sortDirection.Name === 'Desc',
        handleClickColumn: () => handleClickColumn('Name'),
      },

      {
        Header: 'State',
        accessor: 'stateName',
        isServerSorted: selectedSort === 'State',
        isServerSortedDesc: sortDirection.State === 'Desc',
        handleClickColumn: () => handleClickColumn('State'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMarket } };
        }) => {
          return <span>{original.stateName}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        sort: false,
        id: 'actions',
        isServerSorted: false,
        isServerSortedDesc: false,
        handleClickColumn: () => {},
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IMarket } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'center',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                startIcon={<Edit />}
                onClick={() => {
                  handleEditMarket(original);
                }}
              >
                Edit
              </Button>
              <IconButton
                className={classes.button}
                onClick={() => handleDelete(original.marketId)}
              >
                <DeleteForever />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  return (
    <>
      {(areMarketsLoading || isLoading || areStatesLoading) && (
        <Loader type="fullscreen" position="centered" title="Loading..." />
      )}
      <Table
        columns={columns}
        data={markets}
        isLoading={isLoading}
        serverPage={page}
        serverRecordCount={recordCount}
        serverPerPage={perPage}
        handlePage={setPage}
        handleRowsPerPage={setRowsPerPage}
        ResponsiveComponent={isMobile ? MobileTable : undefined}
        mobileProps={{
          fields: columns
            .filter(col => col.Header)
            .map(col => ({
              name: col.Header,
              accessor: col.accessor,
              Cell: col?.Cell,
            })),
          handleEdit: (market: IMarket) => {
            handleEditMarket(market);
          },
          handleDelete: (market: IMarket) => handleDelete(market.marketId),
        }}
      />
      {!areStatesLoading && (
        <MarketModal
          open={isMarketModalOpen}
          onClose={handleCloseMarketModal}
          market={currentMarket}
          fetchMarkets={fetchMarkets}
          states={states}
          areStatesLoading={areStatesLoading}
        />
      )}
    </>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
