import { Edit, Close } from '@mui/icons-material';
import {
  Autocomplete,
  Box,
  Button,
  Fade,
  Grid,
  InputAdornment,
  TextField,
  Typography,
} from '@mui/material';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { useSnackbar } from 'notistack';
import { FC, useEffect, useState } from 'react';
import { getDVMContractors } from '../../fetch/lookups';
import {
  createRSSShiftRequest,
  updateRSSShift,
  updateRSSShiftRequest,
} from '../../fetch/shift-requests';
import { formatDate } from '../../helpers';
import {
  IConfiguredShiftCalendar,
  IMarketCalendarLocation,
  IMarketCalendarShiftRequest,
  IShift,
} from '../../models/schedule';
import { IDropdownResponse, IDVMDropdown } from '../../models/util';
import { Coin } from '../icons/coin';

import { Modal } from '../modal';

interface IRSSAddEditModal {
  isOpen: boolean;
  onClose: () => void;
  date: Date;
  location: IMarketCalendarLocation;
  fetchSchedule: () => Promise<void> | undefined;
  shift?: IShift;
  scheduledShift?: IMarketCalendarShiftRequest;
  thisDaysIncentive: number | null;
}

export const RSSAddEditModal: FC<IRSSAddEditModal> = ({
  isOpen,
  onClose,
  date,
  location,
  fetchSchedule,
  shift,
  scheduledShift,
  thisDaysIncentive,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [contractors, setContractors] = useState<IDVMDropdown[] | null>(null);
  const [areContractorsLoading, setAreContractorsLoading] = useState<boolean>(false);
  const [selectedContractor, setSelectedContractor] = useState<IDVMDropdown | null>(null);
  const [selectedAmount, setSelectedAmount] = useState<number | string | null>(null);
  const [selectedIncentive, setSelectedIncentive] = useState<number | string | null>(null);

  useEffect(() => {
    setSelectedAmount(
      shift?.amount ? shift?.amount : scheduledShift?.amount ? scheduledShift?.amount : null
    );
    setSelectedIncentive(
      typeof shift?.incentive === 'number'
        ? shift?.incentive
        : typeof scheduledShift?.incentive === 'number' || scheduledShift?.incentive === null
        ? scheduledShift?.incentive
        : thisDaysIncentive
    );
    // setSelectedContractor(
    //   shift?.dvmId ? shift?.dvmId : scheduledShift?.dvmId ? scheduledShift?.dvmId : null
    // );
  }, [shift, scheduledShift, thisDaysIncentive]);

  const getDVMName = () => {
    return shift?.dvmName
      ? shift?.dvmName
      : scheduledShift?.dvmName
      ? scheduledShift?.dvmName
      : null;
  };

  const handleClose = () => {
    setSelectedAmount(null);
    setSelectedIncentive(null);
    setSelectedContractor(null);
    onClose();
  };

  const fetchContractors = async () => {
    try {
      setAreContractorsLoading(true);
      const res = await getDVMContractors({
        locationId: location.locationId,
        shiftDate: date.toDateString(),
      });
      setContractors(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(
        errorMessage || `Error loading Contractors, please refresh the page and try again.`,
        {
          variant: 'error',
        }
      );
      console.log(error);
    } finally {
      setAreContractorsLoading(false);
    }
  };

  const getTitle = () => {
    if (shift) return `Edit Shift: ${location?.locationName} - ${formatDate(date)}` ?? '';
    if (scheduledShift)
      return `Edit Shift Request: ${location?.locationName} - ${formatDate(date)}` ?? '';

    return `${location?.locationName} - ${formatDate(date)}` ?? '';
  };

  const [isSubmitting, setIsSubmitting] = useState(false);
  const addRequest = async () => {
    try {
      setIsSubmitting(true);

      if (shift) {
        await updateRSSShift({
          amount: selectedAmount as number,
          shiftId: shift.shiftId as number,
          incentive: selectedIncentive as number,
        });
      } else if (scheduledShift) {
        await updateRSSShiftRequest({
          amount: selectedAmount as number,
          shiftRequestId: scheduledShift.shiftRequestId,
          incentive: selectedIncentive as number,
        });
      } else {
        await createRSSShiftRequest({
          shiftRequest: {
            locationId: location.locationId,
            shiftDate: new Date(date).toDateString(),
            amount: selectedAmount as string,
            dvmId: selectedContractor?.value as string,
            incentive: selectedIncentive as string,
            configuredShiftId:
              (location?.configuredShifts as unknown as IConfiguredShiftCalendar[])?.find(
                cs => new Date(cs?.shiftDate)?.toDateString() === new Date(date)?.toDateString()
              )?.configuredShiftId ?? undefined,
          },
        });
      }
      await fetchSchedule();
      handleClose();
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error creating a request, please try again.`, {
        variant: 'error',
      });

      console.log(error);
    } finally {
      setIsSubmitting(false);
    }
  };

  const isEdit = !!shift || !!scheduledShift;

  useEffect(() => {
    if (location && date && !isEdit) fetchContractors();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location, date, isEdit]);

  return (
    <Modal
      titleContent={getTitle()}
      open={isOpen}
      onClose={() => {
        handleClose();
      }}
      maxWidth="md"
    >
      <Fade in={isOpen}>
        <Grid container spacing={2} alignItems={'flex-end'}>
          {!isEdit ? (
            <Grid item xs={12} sm={4}>
              <Autocomplete
                value={selectedContractor as IDropdownResponse}
                onChange={(event, newValue: any) => {
                  setSelectedContractor(newValue);
                }}
                disabled={areContractorsLoading}
                selectOnFocus
                handleHomeEndKeys
                loading={areContractorsLoading}
                id="selected-dvm"
                options={contractors || []}
                getOptionLabel={(option: IDropdownResponse) => {
                  // Value selected with enter, right from the input
                  if (typeof option === 'string') {
                    return option;
                  }
                  return `${option.description}`;
                }}
                renderInput={params => (
                  <TextField
                    {...params}
                    key={params.id}
                    size="small"
                    autoComplete="on"
                    label="Available DVM's"
                    variant="standard"
                  />
                )}
              />
            </Grid>
          ) : (
            <Grid item xs={12} sm={4}>
              <TextField label="DVM" variant={'standard'} disabled value={getDVMName()} />
            </Grid>
          )}
          <Grid item xs={12} sm={4}>
            <TextField
              label="Requested Rate"
              variant={'standard'}
              value={selectedAmount ?? ''}
              onChange={e => {
                const re = /^[0-9\b]+$/;
                // make sure it is number only before we update state
                if (e.target.value === '' || re.test(e.target.value)) {
                  setSelectedAmount(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">/day</InputAdornment>,
              }}
              // https://github.com/mui/material-ui/issues/9046
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </Grid>
          <Grid item xs={12} sm={4}>
            <TextField
              label="Incentive Amount"
              variant={'standard'}
              value={selectedIncentive ?? ''}
              onChange={e => {
                const re = /^[0-9\b]+$/;
                // make sure it is number only before we update state
                if (e.target.value === '' || re.test(e.target.value)) {
                  setSelectedIncentive(e.target.value);
                }
              }}
              InputProps={{
                startAdornment: <InputAdornment position="start">$</InputAdornment>,
                endAdornment: <InputAdornment position="end">/day</InputAdornment>,
              }}
              // https://github.com/mui/material-ui/issues/9046
              inputProps={{
                inputMode: 'numeric',
                pattern: '[0-9]*',
              }}
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h3" color="textSecondary">
              Total: {selectedIncentive ? <Coin maxHeight={20} /> : ''}
              {`$${Number(selectedAmount) + Number(selectedIncentive)}`}
            </Typography>
          </Grid>
          <Grid item xs={12} textAlign={'right'}>
            <Box marginTop="1rem">
              <Button
                className={classes.button}
                disabled={isSubmitting || !selectedAmount || (!isEdit && !selectedContractor)}
                startIcon={<Edit />}
                variant="contained"
                color="primary"
                onClick={() => addRequest()}
              >
                Save
              </Button>
              <Button
                className={classes.button}
                type="button"
                variant="contained"
                color="inherit"
                disabled={isSubmitting}
                startIcon={<Close />}
                onClick={() => {
                  handleClose();
                }}
              >
                Cancel
              </Button>
            </Box>
          </Grid>
        </Grid>
      </Fade>
    </Modal>
  );
};
const useStyles = makeStyles((theme: Theme) => ({
  link: {
    display: 'flex',
    gap: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
