import { AccountInfo, AuthenticationResult } from '@azure/msal-browser';
import { useAccount, useMsal } from '@azure/msal-react';
import { AxiosResponse } from 'axios';
import React, { createContext, FC, useEffect, useState } from 'react';
import { Roles } from '../constants';
import { authFetch } from '../fetch';
import { setLocalStorage, removeLocalStorage, token_key } from '../helpers';
import { IUser, IValidateUser } from '../models';

interface IUserContext {
  setUser: (user: IUser | null) => void;
  user: IUser | null;
  account: AccountInfo | null;
  isDVM: boolean;
  isDVMPending: boolean;
  isDVMContractor: boolean;
  isDVMEmployee: boolean;
  isActive: boolean;
  isSysAdmin: boolean;
  isRSS: boolean;
  isPracticeManager: boolean;
  isFetching: boolean;
  getUserInfo: any;
  isUserInAnyValidRole: boolean;
  isSetUp: boolean;
  isDVMDeactivated: boolean;
}

interface IUserContextHandlerProps {
  children: React.ReactNode;
}

export const UserContext = createContext<IUserContext>({
  setUser: () => {},
  user: null,
  isDVM: false,
  isDVMPending: false,
  isDVMContractor: false,
  isDVMEmployee: false,
  isActive: false,
  isSysAdmin: false,
  isRSS: false,
  isPracticeManager: false,
  isFetching: true,
  isUserInAnyValidRole: false,
  getUserInfo: null,
  account: null,
  isSetUp: true,
  isDVMDeactivated: true,
});

export const UserContextHandler: FC<IUserContextHandlerProps> = ({ children }) => {
  const { accounts } = useMsal();
  const account = useAccount(accounts[0] ?? {});

  const [user, setUser] = useState<IUser | null>(null);
  const [isFetching, setFetching] = useState<boolean>(true);
  const [isSetUp, setIsSetUp] = useState(true);

  const getUserInfo = async () => {
    setFetching(true);
    if (!account) {
      setFetching(false);
      return;
    }

    try {
      // Had to move this
      const DVM: AxiosResponse<{ isSetUp: boolean }> = await authFetch.get('/user/IsSetUp');

      setIsSetUp(DVM.data.isSetUp);

      const validation: AxiosResponse<IValidateUser> = await authFetch.get('/user/validate');

      // We're having some race conditions when dealing with app roles
      // Information isn't propagating through azure as fast as we need it to
      // so we're injecting a short delay.
      if (validation.data.value.forceTokenRefresh) {
        let holder: AuthenticationResult | null = null;
        do {
          await new Promise(res => setTimeout(res, 1000));
          holder = await authFetch.forceRefresh();
        } while (!holder!.idTokenClaims.hasOwnProperty('roles'));
        //This is here to force the msal account to update.
        // if you have a better way, do it
        window.location.reload();
      }

      await handleSetUser({
        account,
        validation: {
          ...validation.data,
        },
      });
    } catch (error) {
      console.error(error);
    } finally {
      setFetching(false);
    }
  };

  const handleSetUser = async (user: IUser | null) => {
    if (user) {
      await setLocalStorage(`${token_key}`, user);
      setUser(user);
    } else {
      await removeLocalStorage(`${token_key}`);
      setUser(null);
    }
  };

  useEffect(() => {
    getUserInfo();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [account]);

  return (
    // This should be where the signed in user's state lives. Testing user state should be as simple as setting these fields to true or false
    <UserContext.Provider
      value={{
        user: user,
        account: account,
        setUser: handleSetUser,
        isFetching,
        isActive: !!user?.validation?.value?.isActive,
        isDVM: !isFetching && !!user?.account.idTokenClaims?.roles?.includes('DVM'),
        isDVMPending:
          !!user?.account.idTokenClaims?.roles?.includes('DVM') &&
          user?.validation?.value?.dvmInformation?.dvmStatus === 'Pending' &&
          user?.validation?.value?.isActive,
        isDVMContractor: user?.validation?.value?.dvmInformation?.dvmType === 'Contractor',
        isDVMEmployee: user?.validation?.value?.dvmInformation?.dvmType === 'Employee',
        isUserInAnyValidRole:
          !!account?.idTokenClaims?.roles && account?.idTokenClaims?.roles?.length > 0,
        isSysAdmin:
          !isFetching && !!user?.account.idTokenClaims?.roles?.includes(Roles.SYSTEM_ADMIN),
        isRSS: !isFetching && !!user?.account.idTokenClaims?.roles?.includes(Roles.RSS),
        isPracticeManager: !isFetching && !!user?.account.idTokenClaims?.roles?.includes(Roles.PRACTICE_MANAGER),
        getUserInfo: getUserInfo,
        isSetUp: isSetUp,
        isDVMDeactivated: user?.validation?.value?.dvmInformation?.dvmStatus === 'Deactivated',
      }}
    >
      {children}
    </UserContext.Provider>
  );
};
