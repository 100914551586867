import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Table } from '../../components/table/Table';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IconHeading } from '../../components/icon-heading';
import { IColumn, sortable } from '../../models/util';
import { AttachMoney, DeleteForever, EditLocationAlt } from '@mui/icons-material';
import { IIncentiveRate } from '../../models/incentiveRates';
import { useSnackbar } from 'notistack';
import { deleteIncentiveRate, getIncentiveRates } from '../../fetch/incentive-rates';
import { IncentiveRatesModal } from './incentive-rates-modal';
import { formatDate, formatMoney } from '../../helpers';

interface ILocationShifts {
  isLoading: boolean;
  locationId: string;
  canEdit: boolean;
}
export const IncentiveRates: FC<ILocationShifts> = ({ isLoading, locationId, canEdit }) => {
  const classes = useStyles();
  const [isLoadingIncentiveRates, setLoadingIncentiveRates] = useState(false);
  const [incentiveRates, setIncentiveRates] = useState<IIncentiveRate[]>([]);
  const [selectedIncentive, setSelectedIncentive] = useState<IIncentiveRate | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState<string>('startDate');
  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(5);
  const [recordCount, setRecordCount] = useState(0);
  const [sortDirection, setSortDirection] = useState<{
    startDate?: sortable;
    rate?: sortable;
  }>({
    startDate: 'Asc',
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchIncentiveRates = async () => {
    setLoadingIncentiveRates(true);
    try {
      if (locationId === 'new-location') return;

      const res = await getIncentiveRates(locationId, {
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
      });
      setIncentiveRates(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading Incentive Rates, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setLoadingIncentiveRates(false);
    }
  };

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  useEffect(() => {
    fetchIncentiveRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection]);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Date',
        accessor: 'startDate',
        isServerSorted: selectedSort === 'startDate',
        isServerSortedDesc: sortDirection.startDate === 'Desc',
        handleClickColumn: () => handleClickColumn('startDate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IIncentiveRate } };
        }) => {
          return (
            <span>
              {original.endDate !== original.startDate
                ? `${formatDate(original.startDate)} - ${formatDate(original.endDate)}`
                : formatDate(original.startDate)}
            </span>
          );
        },
      },
      {
        Header: 'Incentive',
        accessor: 'rate',
        isServerSorted: selectedSort === 'rate',
        isServerSortedDesc: sortDirection.rate === 'Desc',
        handleClickColumn: () => handleClickColumn('rate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IIncentiveRate } };
        }) => {
          return <span>{formatMoney(original.rate, 0)}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IIncentiveRate } };
        }) => {
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}
            >
              <Button
                className={classes.button}
                color="primary"
                disabled={!canEdit}
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original);
                }}
              >
                Edit
              </Button>
              <IconButton
                disabled={!canEdit}
                className={classes.button}
                onClick={async () => await handleDelete(original.incentiveRateId)}
              >
                <DeleteForever />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  const addIncentiveRate = () => {
    setIsModalOpen(true);
  };
  const handleEdit = (incentive: IIncentiveRate) => {
    setSelectedIncentive(incentive);
    setIsModalOpen(true);
  };
  const handleDelete = async (incentiveRateId: number) => {
    const result = window.confirm('Are you sure you want to delete this Incentive Rate?');
    if (result) {
      await deleteIncentiveRate(incentiveRateId);
      fetchIncentiveRates();
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const addButtonDisabled = locationId === 'new-location' || !canEdit;

  const open = Boolean(anchorEl);
  return (
    <Grid key={'incentiveRates'} className={classes.incentiveRates} container spacing={1}>
      <Grid className={classes.buttons} item xs={6}>
        <IconHeading icon={AttachMoney} title="Incentive Rates" variant="h2" />
      </Grid>
      <Grid className={classes.buttons} item xs={6}>
        <Popover
          id="disabled-popover-new-location"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>
            Please save the Location details to add Incentive Rates.
          </Typography>
        </Popover>
        {/* Needed to wrap this in a <span /> to have any effect on a disabled button. Disabled elements do not fire events.  */}
        <span
          onMouseEnter={addButtonDisabled ? handlePopoverOpen : undefined}
          onMouseLeave={addButtonDisabled ? handlePopoverClose : undefined}
        >
          <Button disabled={addButtonDisabled} onClick={() => addIncentiveRate()}>
            Add
          </Button>
        </span>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={incentiveRates}
          isLoading={isLoading || isLoadingIncentiveRates}
          serverPage={page}
          serverRecordCount={recordCount}
          serverPerPage={perPage}
          handlePage={setPage}
          handleRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Grid>
      <IncentiveRatesModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedIncentive(undefined);
        }}
        fetchIncentiveRates={() => fetchIncentiveRates()}
        incentive={selectedIncentive}
        locationId={locationId}
      />
    </Grid>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  incentiveRates: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
