import { FC, useMemo } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Autocomplete,
  Box,
  Button,
  Fade,
  FormHelperText,
  Grid,
  TextField,
  Typography,
} from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Edit, Close } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { IMarket } from '../../models/markets';
import { IDropdownResponse } from '../../models/util';
import { createMarket, updateMarket } from '../../fetch';

interface IMarketModal {
  open: boolean;
  onClose: () => void;
  market: IMarket | undefined;
  fetchMarkets: () => void;
  states: IDropdownResponse[];
  areStatesLoading: boolean;
}

const Schema = Yup.object().shape({
  name: Yup.string().required('Required'),
  stateName: Yup.string().required('Required'),
});

export const MarketModal: FC<IMarketModal> = ({
  open,
  onClose,
  market,
  fetchMarkets,
  states,
  areStatesLoading,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const selectedState = useMemo(
    () => states.find(state => market?.stateName === state.description),
    [market?.stateName, states]
  );

  return (
    <>
      <Formik
        enableReinitialize={true}
        initialValues={{
          marketId: market?.marketId ?? 0,
          name: market?.name ?? '',
          stateId: selectedState?.value ?? 0,
          stateName: market?.stateName ?? '',
          stateAbbreviation: market?.stateAbbreviation ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const data = {
            market: {
              marketId: market?.marketId ?? 0,
              name: values.name,
              stateId: values.stateId,
            },
          };
          try {
            if (!!market) {
              await updateMarket(data);
              enqueueSnackbar(`Market updated!`, {
                variant: 'success',
              });
            }
            if (!market) {
              await createMarket(data);
              enqueueSnackbar(`Market created!`, {
                variant: 'success',
              });
            }
            actions.resetForm();
            fetchMarkets();
            onClose();
          } catch (error: any) {
            if (!!market) {
              const errorMessage = error?.response?.data?.Detail;
              enqueueSnackbar(errorMessage || `Market was not updated.`, {
                variant: 'error',
              });
            }
            if (!market) {
              const errorMessage = error?.response?.data?.Detail;
              enqueueSnackbar(errorMessage || `Market was not created`, {
                variant: 'success',
              });
            }
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          validateField,
          handleSubmit,
          dirty,
          isValid,
          setFieldTouched,
          handleBlur,
          handleChange,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                resetForm();
                onClose();
              }}
              maxWidth="md"
            >
              <Fade in={open}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  <div>
                    <Typography variant="h5">
                      {!!market ? `Edit ${market?.name}` : `Add New Market`}
                    </Typography>

                    <div className={classes.content}>
                      <Grid container spacing={1}>
                        <Grid item xs={12}>
                          <TextField
                            fullWidth
                            variant="standard"
                            autoComplete="nope"
                            label="Market Name"
                            name="name"
                            value={values.name}
                            onBlur={handleBlur}
                            size="small"
                            required
                            onChange={e => setFieldValue('name', e.target.value)}
                            error={touched.name && errors && errors.name ? true : false}
                            helperText={touched.name && errors && errors.name}
                          />
                        </Grid>

                        <Grid item xs={12}>
                          <Autocomplete
                            value={
                              states.find(
                                state => state.description === values.stateName
                              ) as IDropdownResponse
                            }
                            onChange={(event, newValue: any) => {
                              setFieldValue('stateName', newValue?.description ?? '');
                              setFieldValue('stateId', newValue?.value ?? '');
                              setFieldValue('stateAbbreviation', newValue?.shorthand ?? '');
                              validateField('stateName');
                            }}
                            onSelect={e => {
                              handleBlur(e);
                            }}
                            disabled={areStatesLoading}
                            handleHomeEndKeys
                            loading={areStatesLoading}
                            id="stateName"
                            options={states || []}
                            onBlur={handleBlur}
                            getOptionLabel={(option: IDropdownResponse) => {
                              // Value selected with enter, right from the input
                              if (typeof option === 'string') {
                                return option;
                              }
                              return `${option.description}`;
                            }}
                            renderInput={params => (
                              <TextField
                                {...params}
                                key={params.id}
                                size="small"
                                autoComplete="on"
                                label="State"
                                variant="standard"
                              />
                            )}
                          />

                          {touched?.stateName && errors?.stateName && (
                            <FormHelperText error>Required</FormHelperText>
                          )}
                        </Grid>
                      </Grid>
                    </div>
                  </div>
                  <Box marginTop="1rem">
                    <Button
                      className={classes.button}
                      disabled={!dirty || isSubmitting || !isValid}
                      type="submit"
                      startIcon={<Edit />}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting}
                      startIcon={<Close />}
                      onClick={() => {
                        //@ts-ignore
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm(
                            'You have unsaved changes, are you sure you want to exit?'
                          );
                          if (result) {
                            resetForm();
                            onClose();
                          } else {
                            return;
                          }
                        } else {
                          resetForm();
                          onClose();
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              </Fade>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
