import { FC, useState } from 'react';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import * as Yup from 'yup';
// Components
import {
  Box,
  Button,
  Grid,
  InputAdornment,
  TextField,
  Typography,
  useMediaQuery,
  FormControl,
} from '@mui/material';
import { Modal } from '../../components';
import { Form, Formik } from 'formik';
import { useSnackbar } from 'notistack';
import { Close, Check } from '@mui/icons-material';
import { deepEqual } from 'fast-equals';
import { IConfiguredShift } from '../../models/configured-shifts';
import { IDateRange } from '../../models/date';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { DateRangePicker } from '../../components/input/date-range';
import { TimePicker } from '@mui/x-date-pickers/TimePicker';
import { createConfiguredShift, updateConfiguredShift } from '../../fetch';
import { format } from 'date-fns';

interface ICancelRequestModal {
  open: boolean;
  onClose: () => void;
  fetchConfiguredShifts: () => {};
  shift?: IConfiguredShift;
  locationId: string;
}

const Schema = Yup.object().shape({
  shiftTimeStart: Yup.date().nullable().required('Required'),
  shiftTimeEnd: Yup.date().nullable().required('Required'),
  breakDuration: Yup.number(),
  rate: Yup.number(),
});

export const ConfiguredShiftsModal: FC<ICancelRequestModal> = ({
  open,
  onClose,
  fetchConfiguredShifts,
  shift,
  locationId,
}) => {
  const classes = useStyles();
  const { enqueueSnackbar } = useSnackbar();
  const isMobile = useMediaQuery('(max-width: 960px)');
  const isEdit = shift ? true : false;
  const currentDay = new Date();

  const [selectedDateRange, setSelectedDateRange] = useState<IDateRange>({
    startDate: shift?.shiftDateRangeStart ?? null,
    endDate: shift?.shiftDateRangeEnd ?? null,
    key: 'selection',
    inputValue: '',
  });

  const handleClose = () => {
    onClose();
  };

  const handleApiTimeString = (apiTimeString: string) => {
    const today = new Date().toISOString().slice(0, 10);
    const dateTimeString = `${today}T${apiTimeString}`;
    return new Date(dateTimeString);
  };

  const isConfirmDisabled = isEdit ? false : selectedDateRange.startDate === null;

  return (
    <>
      <Formik
        enableReinitialize
        initialValues={{
          shiftDate: shift?.shiftDate ?? null,
          shiftDateRangeStart: shift?.shiftDateRangeStart ?? null,
          shiftDateRangeEnd: shift?.shiftDateRangeEnd ?? null,
          shiftTimeStart: shift?.shiftTime ? handleApiTimeString(shift?.shiftTime.start) : '',
          shiftTimeEnd: shift?.shiftTime ? handleApiTimeString(shift?.shiftTime?.end) : '',
          breakDuration: shift?.breakDuration ?? '',
          rate: shift?.rate ?? '',
        }}
        validationSchema={Schema}
        onSubmit={async (values, actions) => {
          const formattedShiftTimeStart = values?.shiftTimeStart
            ? //@ts-ignore
            format(values?.shiftTimeStart, 'HH:mm:ss')
            : '';
          const formattedShiftTimeEnd = values?.shiftTimeEnd
            ? //@ts-ignore
            format(values?.shiftTimeEnd, 'HH:mm:ss')
            : '';
        
          const data = {
            locationId: parseInt(locationId),
            configuredShiftId: shift?.configuredShiftId ?? 0,
            shiftDateRangeStart: values?.shiftDateRangeStart || null,
            shiftDateRangeEnd: values?.shiftDateRangeEnd || null,
            shiftTime: {
              start: formattedShiftTimeStart,
              end: formattedShiftTimeEnd,
            },
            breakDuration: values?.breakDuration,
            rate: values?.rate || 0, // Default value for rate
          };
        
          const editData = {
            locationId: parseInt(locationId),
            configuredShiftId: shift?.configuredShiftId ?? 0,
            shiftDate: values?.shiftDate,
            shiftTime: {
              start: formattedShiftTimeStart,
              end: formattedShiftTimeEnd,
            },
            breakDuration: values?.breakDuration,
            rate: values?.rate || 0, // Default value for rate
          };
        
          try {
            if (!data?.configuredShiftId) {
              await createConfiguredShift(data);
            } else {
              await updateConfiguredShift(editData);
            }
        
            fetchConfiguredShifts();
            handleClose();
            actions.resetForm();
            enqueueSnackbar(`Shift updated!`, {
              variant: 'success',
            });
          } catch (error: any) {
            const errorMessage = error?.response?.data?.Detail;
            enqueueSnackbar(errorMessage || `Error updating this Shift, please try again.`, {
              variant: 'error',
            });
          }
        }}
      >
        {({
          isSubmitting,
          values,
          initialValues,
          setFieldValue,
          setValues,
          handleSubmit,
          dirty,
          isValid,
          handleBlur,
          errors,
          touched,
          resetForm,
        }) => {
          return (
            <Modal
              open={open}
              onClose={() => {
                setSelectedDateRange({
                  startDate: null,
                  endDate: null,
                  key: 'selection',
                  inputValue: '',
                });
                resetForm();
                handleClose();
              }}
              maxWidth="md"
            >
              <Form onSubmit={handleSubmit} autoComplete="none">
                <div>
                  <Typography variant="h5">Shift</Typography>
                  <div className={classes.content}>
                    <Grid container spacing={1}>
                      <Grid item xs={12} sm={6}>
                        {!isEdit && (
                          <DateRangePicker
                            id="filterDateRange"
                            label="Date Range *"
                            value={selectedDateRange}
                            isDisabled={isSubmitting}
                            onChange={value => {
                              setSelectedDateRange(value);
                              const startDate = value?.startDate
                                ? new Date(value.startDate).toISOString().slice(0, 10)
                                : null;
                              const endDate = value?.endDate
                                ? new Date(value.endDate).toISOString().slice(0, 10)
                                : null;
                              setFieldValue('shiftDateRangeStart', startDate);
                              setFieldValue('shiftDateRangeEnd', endDate);
                            }}
                            fullWidth
                            inputSize="small"
                            minDate={currentDay}
                          />
                        )}
                        {isEdit && (
                          <FormControl className={classes.shiftDate} fullWidth variant="outlined">
                            {!isMobile ? (
                              <DesktopDatePicker
                                disabled={isEdit}
                                disablePast
                                label={'Date'}
                                inputFormat="MM/dd/yyyy"
                                value={values.shiftDate}
                                onChange={(e: any) => {
                                  if (e === null) {
                                    e = '';
                                  }
                                  setFieldValue('shiftDate', e);
                                }}
                                renderInput={(params: any) => {
                                  return (
                                    <TextField
                                      required
                                      size="small"
                                      variant="outlined"
                                      {...params}
                                      error={
                                        touched.shiftDate && errors && errors.shiftDate
                                          ? true
                                          : false
                                      }
                                      helperText={touched.shiftDate && errors && errors.shiftDate}
                                    />
                                  );
                                }}
                              />
                            ) : (
                              <MobileDatePicker
                                disabled={isEdit}
                                disablePast
                                label={'Open Date'}
                                inputFormat="MM/dd/yyyy"
                                value={values.shiftDate}
                                onChange={(e: any) => {
                                  if (e === null) {
                                    e = '';
                                  }
                                  setFieldValue('shiftDate', e);
                                }}
                                renderInput={(params: any) => (
                                  <TextField
                                    required
                                    variant="outlined"
                                    size="small"
                                    {...params}
                                    error={
                                      touched.shiftDate && errors && errors.shiftDate ? true : false
                                    }
                                    helperText={touched.shiftDate && errors && errors.shiftDate}
                                  />
                                )}
                              />
                            )}
                          </FormControl>
                        )}
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TextField
                          fullWidth
                          label="Break Duration In Minutes"
                          variant="outlined"
                          autoComplete="nope"
                          name="breakDuration"
                          value={values?.breakDuration}
                          onBlur={handleBlur}
                          size="small"
                          onChange={e => setFieldValue('breakDuration', e.target.value)}
                          error={
                            touched.breakDuration && errors && errors.breakDuration ? true : false
                          }
                          helperText={touched.breakDuration && errors && errors.breakDuration}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TimePicker
                          label="Start Time"
                          value={values.shiftTimeStart}
                          onChange={(time: any) => {
                            setFieldValue('shiftTimeStart', time);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              required
                              variant="outlined"
                              size="small"
                              {...params}
                              error={
                                touched.shiftTimeStart && errors && errors.shiftTimeStart
                                  ? true
                                  : false
                              }
                              helperText={touched.shiftTimeStart && errors && errors.shiftTimeStart}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={12} sm={6}>
                        <TimePicker
                          label="End Time"
                          value={values.shiftTimeEnd}
                          onChange={(time: any) => {
                            setFieldValue('shiftTimeEnd', time);
                          }}
                          renderInput={(params: any) => (
                            <TextField
                              required
                              variant="outlined"
                              size="small"
                              {...params}
                              error={
                                touched.shiftTimeEnd && errors && errors.shiftTimeEnd ? true : false
                              }
                              helperText={touched.shiftTimeEnd && errors && errors.shiftTimeEnd}
                            />
                          )}
                        />
                      </Grid>
                      <Grid item xs={6}>
                        <TextField
                          fullWidth
                          variant={'outlined'}
                          autoComplete="nope"
                          label="Incentive Amount"
                          name="rate"
                          value={values.rate}
                          onBlur={handleBlur}
                          size="small"
                          InputProps={{
                            startAdornment: <InputAdornment position="start">$</InputAdornment>,
                          }}
                          onChange={e => {
                            const re = /^[0-9\b]+$/;
                            if (e.target.value === '' || re.test(e.target.value)) {
                              setFieldValue('rate', e.target.value);
                            }
                          }}
                          error={touched.rate && errors.rate ? true : false}
                          helperText={touched.rate && errors.rate}
                        />
                      </Grid>
                    </Grid>
                  </div>
                </div>

                <Box marginTop="1rem">
                  <Button
                    className={classes.button}
                    disabled={!isValid || !dirty || isSubmitting || isConfirmDisabled}
                    type="submit"
                    startIcon={<Check />}
                    variant="contained"
                    color="primary"
                  >
                    Confirm
                  </Button>
                  <Button
                    className={classes.button}
                    type="button"
                    variant="contained"
                    color="inherit"
                    disabled={isSubmitting}
                    startIcon={<Close />}
                    onClick={() => {
                      //@ts-ignore
                      if (!deepEqual(initialValues, values)) {
                        const result = window.confirm(
                          'You have unsaved changes, are you sure you want to exit?'
                        );
                        if (result) {
                          resetForm();
                          fetchConfiguredShifts();
                          setSelectedDateRange({
                            startDate: null,
                            endDate: null,
                            key: 'selection',
                            inputValue: '',
                          });
                          handleClose();
                        } else {
                          return;
                        }
                      } else {
                        resetForm();
                        handleClose();
                      }
                    }}
                  >
                    Cancel
                  </Button>
                </Box>
              </Form>
            </Modal>
          );
        }}
      </Formik>
    </>
  );
};

const useStyles = makeStyles((theme: Theme) => ({
  primaryHeader: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    marginBottom: theme.spacing(1),
  },
  marginBottom: {
    marginBottom: theme.spacing(1),
  },
  content: {
    marginTop: theme.spacing(1),
  },
  paginationWrapper: {
    margin: theme.spacing(0.5, 0),
  },
  saveWrapper: {
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: theme.spacing(1),
  },
  deleteButton: {
    color: theme.palette.error.main,
  },
  addButton: {
    flex: 1,
  },
  validationMessage: {
    color: theme.palette.error.main,
    marginTop: theme.spacing(1),
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  reasonSelector: {
    cursor: 'pointer',
  },
  shiftDate: {
    marginTop: 0,
  },
}));
