import { Divider, Grid, Theme, Typography } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import { FC } from 'react';

export interface IPageTitleProps {
  title?: string;
  showDivider?: boolean;
  additionalHeaderContent?: JSX.Element;
  subTitle?: string | JSX.Element;
  marginBottom?: boolean;
  paddingBottom?: number;
  additionalHeaderContentGridSpace?: number;
}

export const PageTitle: FC<IPageTitleProps> = ({
  title,
  showDivider = true,
  additionalHeaderContent,
  marginBottom = true,
  subTitle,
  paddingBottom,
  additionalHeaderContentGridSpace = 6,
}) => {
  const classes = useStyles({ marginBottom, paddingBottom });
  return (
    <>
      <Grid container alignItems="center" className={classes.topWrapper}>
        <Grid item xs={12 - additionalHeaderContentGridSpace}>
          <Typography variant="h1" className={classes.heading}>
            {title}
            <Typography className={classes.subTitle} component="span">
              {subTitle}
            </Typography>
            {showDivider && !additionalHeaderContent && title && (
              <Divider className={classes.divider} />
            )}
          </Typography>
        </Grid>
        {additionalHeaderContent && (
          <Grid item xs={!additionalHeaderContent ? 12 : additionalHeaderContentGridSpace}>
            <div className={classes.additionalItems}>{additionalHeaderContent}</div>
          </Grid>
        )}

        <Grid item xs={12}>
          {showDivider && additionalHeaderContent && title && (
            <Divider className={classes.additionalContentDivider} />
          )}
        </Grid>
      </Grid>
    </>
  );
};

const useStyles = makeStyles<Theme, { marginBottom: boolean; paddingBottom: number | undefined }>(
  theme => ({
    heading: {
      marginBottom: ({ marginBottom }) => (marginBottom ? theme.spacing(0.5) : 0),
      color: theme.palette.common.black,
      textAlign: 'center',
      [theme.breakpoints.up('sm')]: {
        textAlign: 'left',
      },
    },
    topWrapper: {
      marginBottom: theme.spacing(0.5),
      justifyContent: 'center',
      flexDirection: 'column',
      paddingBottom: ({ paddingBottom }) => (paddingBottom ? paddingBottom : 0),
      [theme.breakpoints.up('sm')]: {
        justifyContent: 'flex-start',
        flexDirection: 'row',
      },
    },
    divider: {
      marginTop: 0,
    },
    additionalContentDivider: {
      marginTop: theme.spacing(0.5),
    },
    contentDivider: {
      marginTop: 0,
    },
    additionalItems: {
      [theme.breakpoints.up('sm')]: {
        textAlign: 'right',
      },
    },
    subTitle: {
      display: 'block',
      color: '#000',
      marginTop: 5,
    },
  })
);
