import { Box, Button, Grid, IconButton, Popover, Typography } from '@mui/material';
import { FC, useEffect, useMemo, useState } from 'react';
import { Table } from '../../components/table/Table';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { IconHeading } from '../../components/icon-heading';
import { IColumn, sortable } from '../../models/util';
import { DeleteForever, EditLocationAlt, CalendarMonth } from '@mui/icons-material';
import { IConfiguredShift } from '../../models/configured-shifts';
import { useSnackbar } from 'notistack';
import { deleteConfiguredShift, getConfiguredShifts } from '../../fetch/configured-shifts';
import { ConfiguredShiftsModal } from './configured-shifts-modal';
import { formatDate, formatMoney, convertMilitaryToStandard } from '../../helpers';
import { isBefore, startOfDay } from 'date-fns';

interface ILocationConfiguredShifts {
  isLoading: boolean;
  locationId: string;
}
export const ConfiguredShifts: FC<ILocationConfiguredShifts> = ({ isLoading, locationId }) => {
  const classes = useStyles();
  const [isLoadingConfiguredShifts, setLoadingConfiguredShifts] = useState(false);
  const [configuredShifts, setConfiguredShifts] = useState<IConfiguredShift[]>([]);
  const [selectedShift, setSelectedShift] = useState<IConfiguredShift | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedSort, setSelectedSort] = useState<string>('shiftDate');
  const [page, setPage] = useState(0);
  const [perPage, setRowsPerPage] = useState(5);
  const [recordCount, setRecordCount] = useState(0);
  const [sortDirection, setSortDirection] = useState<{
    shiftDate?: sortable;
    shiftTimeStart?: sortable;
    shiftTimeEnd?: sortable;
    breakDuration?: sortable;
    rate?: sortable;
  }>({
    shiftDate: 'Desc',
  });

  const { enqueueSnackbar } = useSnackbar();

  const fetchConfiguredShifts = async () => {
    setLoadingConfiguredShifts(true);
    try {
      if (locationId === 'new-location') return;

      const res = await getConfiguredShifts(locationId, {
        sortBy: selectedSort,
        // @ts-ignore
        sortDirection: sortDirection[selectedSort],
        page: page + 1,
        perPage,
      });
      setConfiguredShifts(res.records);
      setRecordCount(res.totalRecordCount);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading Shifts, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setLoadingConfiguredShifts(false);
    }
  };

  const handleClickColumn = (column: string) => {
    setSelectedSort(column);
    setSortDirection({
      ...sortDirection,
      // @ts-ignore
      [column]: sortDirection[column] === 'Asc' ? 'Desc' : 'Asc',
    });
  };

  useEffect(() => {
    fetchConfiguredShifts();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [page, perPage, selectedSort, sortDirection]);

  const columns = useMemo((): IColumn[] => {
    return [
      {
        Header: 'Date',
        accessor: 'shiftDate',
        isServerSorted: selectedSort === 'shiftDate',
        isServerSortedDesc: sortDirection.shiftDate === 'Desc',
        handleClickColumn: () => handleClickColumn('shiftDate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          return <span>{formatDate(original?.shiftDate)}</span>;
        },
      },
      {
        Header: 'Start Time',
        accessor: 'shiftTime.start',
        isServerSorted: selectedSort === 'shiftTimeStart',
        isServerSortedDesc: sortDirection.shiftTimeStart === 'Desc',
        handleClickColumn: () => handleClickColumn('shiftTimeStart'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          return <span>{convertMilitaryToStandard(original.shiftTime.start)}</span>;
        },
      },
      {
        Header: 'End Time',
        accessor: 'shiftTime.end',
        isServerSorted: selectedSort === 'shiftTimeEnd',
        isServerSortedDesc: sortDirection.shiftTimeEnd === 'Desc',
        handleClickColumn: () => handleClickColumn('shiftTimeEnd'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          return <span>{convertMilitaryToStandard(original.shiftTime.end)}</span>;
        },
      },
      {
        Header: 'Break',
        accessor: 'breakDuration',
        isServerSorted: selectedSort === 'breakDuration',
        isServerSortedDesc: sortDirection.breakDuration === 'Desc',
        handleClickColumn: () => handleClickColumn('breakDuration'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          return <span>{original.breakDuration ? `${original.breakDuration} minutes` : ''}</span>;
        },
      },
      {
        Header: 'Incentive Amount',
        accessor: 'rate',
        isServerSorted: selectedSort === 'rate',
        isServerSortedDesc: sortDirection.rate === 'Desc',
        handleClickColumn: () => handleClickColumn('rate'),
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          return <span>{formatMoney(original.rate, 0)}</span>;
        },
      },
      {
        Header: '',
        accessor: '',
        id: 'actions',
        Cell: ({
          cell: {
            row: { original },
          },
        }: {
          cell: { row: { original: IConfiguredShift } };
        }) => {
          const today = startOfDay(new Date());
          const shiftDateStartOfDay = startOfDay(new Date(original.shiftDate));
          const isDatePast = isBefore(shiftDateStartOfDay, today);
          return (
            <Box
              sx={{
                textAlign: {
                  xs: 'left',
                  md: 'right',
                },
              }}
            >
              <Button
                className={classes.button}
                disabled={isDatePast}
                color="primary"
                startIcon={<EditLocationAlt />}
                onClick={() => {
                  handleEdit(original);
                }}
              >
                Edit
              </Button>
              <IconButton
                className={classes.button}
                disabled={isDatePast}
                onClick={async () => await handleDelete(original.configuredShiftId)}
              >
                <DeleteForever />
              </IconButton>
            </Box>
          );
        },
      },
    ];
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSort, sortDirection]);

  const addConfiguredShift = () => {
    setIsModalOpen(true);
  };
  const handleEdit = (shift: IConfiguredShift) => {
    setSelectedShift(shift);
    setIsModalOpen(true);
  };
  const handleDelete = async (configuredShiftId: number) => {
    const result = window.confirm('Are you sure you want to delete this Shift?');
    if (result) {
      try {
        await deleteConfiguredShift(configuredShiftId);
        fetchConfiguredShifts();
      } catch (error: any) {
        const errorMessage = error?.response?.data?.Detail;
        enqueueSnackbar(errorMessage || `Error deleting Shift, please try again.`, {
          variant: 'error',
        });
        console.log(error);
      }
    }
  };

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const addButtonDisabled = locationId === 'new-location';

  const open = Boolean(anchorEl);
  return (
    <Grid key={'configuredShifts'} className={classes.configuredShifts} container spacing={1}>
      <Grid className={classes.buttons} item xs={6}>
        <IconHeading icon={CalendarMonth} title="Shifts" variant="h2" />
      </Grid>
      <Grid className={classes.buttons} item xs={6}>
        <Popover
          id="disabled-popover-new-location"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>Please save the Location details to add Shifts.</Typography>
        </Popover>
        {/* Needed to wrap this in a <span /> to have any effect on a disabled button. Disabled elements do not fire events.  */}
        <span
          onMouseEnter={addButtonDisabled ? handlePopoverOpen : undefined}
          onMouseLeave={addButtonDisabled ? handlePopoverClose : undefined}
        >
          <Button disabled={addButtonDisabled} onClick={() => addConfiguredShift()}>
            Add
          </Button>
        </span>
      </Grid>
      <Grid item xs={12}>
        <Table
          columns={columns}
          data={configuredShifts}
          isLoading={isLoading || isLoadingConfiguredShifts}
          serverPage={page}
          serverRecordCount={recordCount}
          serverPerPage={perPage}
          handlePage={setPage}
          handleRowsPerPage={setRowsPerPage}
          rowsPerPageOptions={[5, 10, 25, 50]}
        />
      </Grid>
      <ConfiguredShiftsModal
        open={isModalOpen}
        onClose={() => {
          setIsModalOpen(false);
          setSelectedShift(undefined);
        }}
        fetchConfiguredShifts={() => fetchConfiguredShifts()}
        shift={selectedShift}
        locationId={locationId}
      />
    </Grid>
  );
};
const useStyles = makeStyles<Theme>(theme => ({
  configuredShifts: {
    marginTop: theme.spacing(4),
  },
  buttons: {
    [theme.breakpoints.up('sm')]: {
      textAlign: 'right',
    },
  },
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
}));
