import { IDropdownResponse, IDVMDropdown, ILocationDropdown } from './../models/util';
import { authFetch } from './fetch';
import { logError } from '../services';

const baseUrl = '/lookup';

export const getMarkets = async (filters?: {
  excludeMarketsWithoutLocations: boolean;
}): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/Markets`, filters);
    return data;
  } catch (error) {
    logError(error, 'getMarkets failed');
    return Promise.reject(error);
  }
};

export const getDvmStatuses = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/DVMStatuses`);
    return data;
  } catch (error) {
    logError(error, 'getDvmStatuses failed');
    return Promise.reject(error);
  }
};

export const getDvmTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/DVMTypes`);
    return data;
  } catch (error) {
    logError(error, 'getDvmTypes failed');
    return Promise.reject(error);
  }
};

export const getDvmSchools = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetDVMSchools`);
    return data;
  } catch (error) {
    logError(error, 'getDvmSchools failed');
    return Promise.reject(error);
  }
};

export const getLocationLookup = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetLocations`);
    return data;
  } catch (error) {
    logError(error, 'getLocationLookup failed');
    return Promise.reject(error);
  }
};

export const getLocationsForSchedulingLookup = async (locationType?: number): Promise<ILocationDropdown[]> => {
  try {
    const params = locationType ? { LocationType: locationType } : {};
    const { data } = await authFetch.get(`${baseUrl}/GetLocationsForScheduling`, params);
    return data;
  } catch (error) {
    logError(error, 'getLocationsForSchedulingLookup failed');
    return Promise.reject(error);
  }
};

export const getDVMsLookup = async (filters?: {
  locationId?: string | undefined;
  showAllDvms: boolean;
}): Promise<IDVMDropdown[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetDVMs`, filters);

    return data;
  } catch (error) {
    logError(error, 'getDVMsLookup failed');
    return Promise.reject(error);
  }
};

export const getDVMContractors = async (filters: {
  locationId: number | string | undefined;
  shiftDate: string;
}): Promise<IDVMDropdown[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetDVMContractors`, filters);

    return data;
  } catch (error) {
    logError(error, 'getDVMContractors failed');
    return Promise.reject(error);
  }
};

export const getCancelationReasons = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/CancelationReasons`);
    return data;
  } catch (error) {
    logError(error, 'getCancelationReasons failed');
    return Promise.reject(error);
  }
};

export const getStates = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/States`);
    return data;
  } catch (error) {
    logError(error, 'getStates failed');
    return Promise.reject(error);
  }
};

export const getLegalEntities = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/LegalEntities`);
    return data;
  } catch (error) {
    logError(error, 'getLegalEntities failed');
    return Promise.reject(error);
  }
};

export const getW2DVMs = async (): Promise<IDVMDropdown[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/GetW2DVMs`);
    return data;
  } catch (error) {
    logError(error, 'getW2DVMs failed');
    return Promise.reject(error);
  }
};

export const getTaxTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/TaxTypes`);
    return data;
  } catch (error) {
    logError(error, 'getTaxTypes failed');
    return Promise.reject(error);
  }
};

export const getLocationTypes = async (): Promise<IDropdownResponse[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/LocationTypes`);
    return data;
  } catch (error) {
    logError(error, 'getLocationTypes failed');
    return Promise.reject(error);
  }
};