import { authFetch } from '.';
import { IAvailableShift, IMapLocation } from '../models/maps';
import { logError } from '../services';

const baseUrl = '/maps';

export const getMapLocations = async (filters?: {
  startDate?: Date | null;
  endDate?: Date | null;
  locationTypes?: string[];
}): Promise<IMapLocation[]> => {
  try {
    const activeFilters = filters || {};

    const queryParams = new URLSearchParams();
    Object.keys(activeFilters).forEach(key => {
      const value = activeFilters[key as keyof typeof activeFilters];
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // For array fields with items, add each value under the same key
          value.forEach(item => {
            const formattedItem = item.replaceAll(/\s/g, '');
            queryParams.append(key, formattedItem);
          });
        }
      } else if (value !== undefined && value !== null) {
        // For dates, set  to an ISOStri
        if (value instanceof Date) {
          queryParams.set(key, value.toISOString());
        }
      }
    });

    const { data } = await authFetch.get(
      `${baseUrl}/availableLocationShifts?${queryParams.toString()}`
    );
    return data;
  } catch (error) {
    logError(error, 'getMapLocations failed');
    return Promise.reject(error);
  }
};

export const getAvailableDVMShifts = async (
  locationId: number,
  startDate: Date,
  endDate: Date
): Promise<IAvailableShift[]> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/AvailableDVMShifts`, {
      locationId,
      startDate,
      endDate,
    });
    return data;
  } catch (error) {
    logError(error, 'getAvailableDVMShifts failed');
    return Promise.reject(error);
  }
};
