import { useTheme, useMediaQuery } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import React, { FC, useContext, useEffect, useMemo, useState } from 'react';
import { useLocation } from 'react-router-dom';
import clsx from 'clsx';

import { Footer, Header } from './';
import { SideNav } from '../side-nav';
import { PageTitle } from '../page-title';
import { NavContext, UserContext } from '../../context';
import { Paths, Routes } from '../../constants';

// Placeholder icons for demonstration purposes

import { Theme } from '@mui/material/styles';

interface IPage {
  children: React.ReactNode;
  title: string;
  customPageHeader?: boolean;
  footerAction?: () => void;
  additionalHeaderContent?: JSX.Element;
  contentWidthFull?: true;
  paddingBottom?: number;
  additionalHeaderContentGridSpace?: number;
}

/**
 * Controls both the <Footer /> text display and the window title.
 */
const CLIENT_NAME = 'AVG';
export const SIDENAV_WIDTH_COLLAPSED = 56;
export const SIDENAV_WIDTH_EXPANDED = 240;
const HEADER_HEIGHT = 64;
const FOOTER_HEIGHT = 64;

export const Page: FC<IPage> = ({
  children,
  title,
  customPageHeader = false,
  footerAction,
  additionalHeaderContent,
  paddingBottom,
  additionalHeaderContentGridSpace,
}) => {
  const { pathname } = useLocation();
  const theme = useTheme();
  const isMobile = useMediaQuery(`(max-width: ${theme.breakpoints.values.mobile}px)`);

  const { user, isDVM, isDVMPending, isDVMContractor, isActive, isDVMDeactivated } =
    useContext(UserContext);

  const { isExpanded, setIsExpanded } = useContext(NavContext);

  const sideNavWidth = useMemo(() => {
    if (isExpanded && isMobile) {
      // Show Icons and Labels
      return SIDENAV_WIDTH_EXPANDED;
    }
    if (isExpanded) {
      // Show Icons and Labels
      return SIDENAV_WIDTH_EXPANDED;
    } else if (!isExpanded) {
      // Only show Icons
      return SIDENAV_WIDTH_COLLAPSED;
    } else {
      // Hide completely
      return 0;
    }
  }, [isExpanded, isMobile]);

  const classes = useStyles({
    sideNavWidth,
    showFooter: !!footerAction,
    isMobile,
    isDVMPending,
    contentWidthFull: true,
  });

  useEffect(() => {
    document.title = `${title} | ${CLIENT_NAME}`;
  }, [pathname, title]);

  const [navItems, setNavItems] = useState(Routes);

  const showSideNav = () => {
    if (isDVMDeactivated) {
      return false;
    }
    if (!isDVMPending && isActive) {
      return true;
    }

    return false;
  };
  useEffect(() => {
    setNavItems(
      Routes.filter(route => {
        // if User is DVM and has 'Pending' status
        if (isDVMPending) {
          return route.path === Paths.DVM_PENDING;
        }
        return true;
      })
        .filter(route => {
          // if User has no Roles
          if (
            !user?.account?.idTokenClaims?.roles ||
            user?.account?.idTokenClaims?.roles.length < 1 ||
            !user?.validation?.value.isActive
          ) {
            return route.path === Paths.HOME;
          }

          if (route.path === Paths.HOME && isDVMContractor) {
            return true;
          }

          // Filter out routes that the user's role does not provide access to
          return user?.account?.idTokenClaims?.roles?.some(role =>
            route.allowedRoles?.includes(role)
          );
        })
        .filter(route => {
          //For ease of reading, if a route has more than roles that determine if it should show, add those checks here.
          if (route.path === Paths.DVM_PROFILE) {
            // Extra checks for DVM Profile

            return isDVM && user?.validation?.value?.isActive;
          }
          return true;
        })
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, isDVM]);

  return (
    <div className={classes.appFrame}>
      <Header setShowNav={setIsExpanded} showNav={isExpanded} height={HEADER_HEIGHT} />

      {/* SIDE NAVIGATION */}
      {showSideNav() && (
        <SideNav
          open={isExpanded}
          headerHeight={HEADER_HEIGHT}
          setShowNav={setIsExpanded}
          isMobile={isMobile}
          width={sideNavWidth}
          navItems={navItems}
        />
      )}

      {/* BODY CONTENT */}
      <div
        className={clsx(classes.contentWrapper, {
          [classes.contentShift]: isExpanded,
        })}
      >
        <div className={classes.content}>
          {!customPageHeader && (
            <PageTitle
              title={title}
              additionalHeaderContent={additionalHeaderContent}
              paddingBottom={paddingBottom}
              additionalHeaderContentGridSpace={additionalHeaderContentGridSpace}
            />
          )}
          {children}
        </div>
      </div>

      {!!footerAction && <Footer clientName={CLIENT_NAME} height={FOOTER_HEIGHT} />}
    </div>
  );
};

const useStyles = makeStyles<
  Theme,
  {
    sideNavWidth: number;
    showFooter: boolean;
    isMobile: boolean;
    isDVMPending: boolean;
    contentWidthFull: boolean;
  }
>((theme: Theme) => ({
  appFrame: {
    height: '100vh',
    [theme.breakpoints.down('mobile')]: {
      overflowY: 'scroll',
    },

    overflow: 'hidden',
    backgroundColor: theme.palette.common.white,
  },
  contentWrapper: {
    [theme.breakpoints.down('mobile')]: {
      width: `100%`,
    },
    [theme.breakpoints.up('mobile')]: {
      width: ({ sideNavWidth, isDVMPending }) =>
        isDVMPending ? `100%` : `calc(100% - (${sideNavWidth}px }))`,
      transition: theme.transitions.create('width', {
        easing: theme.transitions.easing.easeInOut,
        duration: theme.transitions.duration.enteringScreen,
      }),
    },
    marginLeft: ({ sideNavWidth, isMobile, isDVMPending }) =>
      isMobile || isDVMPending ? `0` : `${sideNavWidth}px`,
    display: 'flex',
    position: 'fixed',
    right: 0,
    left: 0,

    top: theme.spacing(8),
    bottom: ({ showFooter }) => (showFooter ? theme.spacing(8) : 0),
    overflow: 'auto',
    height: `calc(100% - ${theme.spacing(8)})`,
  },
  content: {
    boxSizing: 'border-box',
    overflowX: 'clip',
    width: ({ contentWidthFull }) =>
      contentWidthFull ? '100%' : `calc(100% - ${theme.spacing(4)})`,
    padding: theme.spacing(3),
    [theme.breakpoints.down('mobile')]: {
      padding: theme.spacing(1, 3),
    },
  },
  contentShift: {
    // Account for side nav, plus left margin that would have been added
    // from contentWrapper class rules
    [theme.breakpoints.up('mobile')]: {
      marginLeft: ({ sideNavWidth }) => `${sideNavWidth}px`,
    },
    transition: theme.transitions.create('margin', {
      easing: theme.transitions.easing.easeOut,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
}));
