import { authFetch } from './fetch';
import { ILocation, ILocationContainer } from '../models/locations';
import { IResponse } from '../models/util';
import { logError } from '../services';

const baseUrl = '/locations';

export const getLocations = async (filters?: {
  page?: number;
  perPage?: number;
  sortBy?: string;
  sortDirection?: string;
  searchTerm?: string;
}): Promise<IResponse<ILocation[]>> => {
  try {
    const activeFilters = filters || {};

    const queryParams = new URLSearchParams();
    Object.keys(activeFilters).forEach(key => {
      const value = activeFilters[key as keyof typeof activeFilters];
      if (Array.isArray(value)) {
        if (value.length > 0) {
          // For array fields with items, add each value under the same key
          value.forEach(item => queryParams.append(key, item));
        } else if (key.toLowerCase() === 'marketIds') {
          // If DVMStatuses is empty, still add it to the query string with no value
          queryParams.append(key, '');
        }
      } else if (value !== undefined) {
        // For non-array fields, just set the parameter
        queryParams.set(key, value.toString());
      }
    });
    const { data } = await authFetch.get(`${baseUrl}?${queryParams.toString()}`);
    return data;
  } catch (error) {
    logError(error, 'getLocations failed');
    return Promise.reject(error);
  }
};
export const getLocation = async (locationId: number): Promise<ILocation> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/${locationId}`);
    return data;
  } catch (error) {
    logError(error, 'getLocation failed');
    return Promise.reject(error);
  }
};

export const createLocation = async (location: ILocationContainer): Promise<string> => {
  try {
    const { data } = await authFetch.post(`${baseUrl}`, location);
    return data;
  } catch (error) {
    logError({error, payload: location}, 'createLocation failed');
    return Promise.reject(error);
  }
};

export const updateLocation = async (location: ILocationContainer): Promise<any> => {
  try {
    const data = await authFetch.put(`${baseUrl}`, location);
    return data;
  } catch (error) {
    logError({error, payload: location}, 'updateLocation failed');
    return Promise.reject(error);
  }
};
export const deleteLocation = async (locationId: number): Promise<ILocation> => {
  try {
    const { data } = await authFetch.delete(`${baseUrl}/${locationId}`);
    return data;
  } catch (error) {
    logError(error, 'deleteLocation failed');
    return Promise.reject(error);
  }
};

export const getLocationHours = async (locationId: number): Promise<any> => {
  try {
    const { data } = await authFetch.get(`${baseUrl}/hours/${locationId}`);
    return data;
  } catch (error) {
    logError(error, 'getLocationHours failed');
    return Promise.reject(error);
  }
};