import {
  Box,
  Button,
  FormControl,
  FormControlLabel,
  FormHelperText,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Switch,
  TextField,
  useMediaQuery,
} from '@mui/material';
import { FC, useState, useEffect, useContext } from 'react';
import { UserContext } from '../../context';
import { Loader, Page } from '../../components';
import {
  createLocation,
  getLocation,
  updateLocation,
  getLocationHours,
} from '../../fetch/locations';
import { ILocation, ILocationShift } from '../../models';
import { useSnackbar } from 'notistack';
import * as Yup from 'yup';
import { Close, Edit } from '@mui/icons-material';
import LocationOnOutlinedIcon from '@mui/icons-material/LocationOnOutlined';
import WorkspacesIcon from '@mui/icons-material/Workspaces';
import { deepEqual } from 'fast-equals';
import { Formik, Form } from 'formik';
import { useHistory, useParams } from 'react-router-dom';
import { Theme } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import { getLegalEntities, getLocationTypes, getMarkets } from '../../fetch/lookups';
import { IDropdownResponse } from '../../models/util';
import { LocationShifts } from '.';
import { IconHeading } from '../../components/icon-heading';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { MobileDatePicker } from '@mui/x-date-pickers/MobileDatePicker';
import { IncentiveRates } from './incentive-rates';
import { getStates } from '../../fetch/lookups';
import { ConfiguredShifts } from './configuredShifts';

export enum LocationType {
  UrgentVet = '1',
  GeneralPractice = '2',
}

export const LocationsDetails: FC = () => {
  const { isPracticeManager, isSysAdmin, isRSS } = useContext(UserContext);

  const [isLoading, setIsLoading] = useState<boolean>(true);
  const [location, setLocation] = useState<ILocation | null>(null);
  const [shifts, setShifts] = useState<ILocationShift[]>([]);

  const classes = useStyles();

  const isMobile = useMediaQuery('(max-width: 960px)');
  const { enqueueSnackbar } = useSnackbar();
  const history = useHistory();
  const { locationId }: { locationId: string } = useParams();

  function isValidDate(date: string) {
    const date1 = new Date(date);
    if (!isNaN(date1.getTime())) {
      return true;
    }
    const date2 = new Date(date.replace(/(\d{4}-\d{2}-\d{2})T(\d{2}:\d{2}:\d{2})/, '$1T$2Z'));
    if (!isNaN(date2.getTime())) {
      return true;
    }
    return false;
  }

  // visibility & permissions conditions based on roles
  const canViewRoles = isPracticeManager && !isSysAdmin && !isRSS;
  const canEdit =  !isPracticeManager || isSysAdmin || isRSS;

  const isEdit = locationId !== 'new-location';
  const fetchLocation = async (id: number) => {
    try {
      setIsLoading(true);
      const res = await getLocation(id);
      const hoursRes = await getLocationHours(id);
      setLocation(res);
      setShifts([hoursRes] ?? []);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading locations, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  const [isMarketsLoading, setIsMarketsLoading] = useState(true);
  const [markets, setMarkets] = useState<IDropdownResponse[]>([]);
  const fetchMarkets = async () => {
    setIsMarketsLoading(true);
    try {
      setIsLoading(true);
      const res = await getMarkets();
      setMarkets(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading markets, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsMarketsLoading(false);
      setIsLoading(false);
    }
  };

  const [areLegalEntitiesLoading, setIsLegalEntitiesLoading] = useState(true);
  const [legalEntities, setLegalEntities] = useState<IDropdownResponse[]>([]);
  const fetchLegalEntities = async () => {
    setIsLegalEntitiesLoading(true);
    try {
      setIsLoading(true);
      const res = await getLegalEntities();
      setLegalEntities(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading legal entities, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsLegalEntitiesLoading(false);
      setIsLoading(false);
    }
  };

  const [areStatesLoading, setIsStatesLoading] = useState(true);
  const [states, setStates] = useState<IDropdownResponse[]>([]);
  const fetchStates = async () => {
    setIsStatesLoading(true);
    try {
      setIsLoading(true);
      const res = await getStates();
      setStates(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading states, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setIsStatesLoading(false);
      setIsLoading(false);
    }
  };

  // LocationTypes
  const [areLocationTypesLoading, setAreLocationTypesLoading] = useState(true);
  const [locationTypes, setLocationTypes] = useState<IDropdownResponse[]>([]);
  const fetchLocationTypes = async () => {
    setAreLocationTypesLoading(true);
    try {
      setIsLoading(true);
      const res = await getLocationTypes();
      setLocationTypes(res);
    } catch (error: any) {
      const errorMessage = error?.response?.data?.Detail;
      enqueueSnackbar(errorMessage || `Error loading location types, please try again.`, {
        variant: 'error',
      });
      console.log(error);
    } finally {
      setAreLocationTypesLoading(false);
      setIsLoading(false);
    }
  };
  useEffect(() => {
    // Values for Dropdowns
    fetchMarkets();
    fetchLegalEntities();
    fetchStates();
    fetchLocationTypes();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEdit) {
      fetchLocation(parseInt(locationId));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [locationId]);

  const Schema = Yup.object().shape({
    name: Yup.string().required('Required'),
    marketName: Yup.string().required('Required'),
    address: Yup.string().required('Required'),
    city: Yup.string().required('Required'),
    state: Yup.string().required('Required'),
    zip: Yup.string().length(5).required('Required'),
    accountsPayableId: Yup.string(),
    openDate: Yup.string(),
    closeDate: Yup.string(),
    locationShifts: Yup.array(
      Yup.object().shape({
        locationShiftId: Yup.number(),
        effectiveDate: Yup.date().required('Required'),
        monday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        tuesday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        wednesday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        thursday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        friday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        saturday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        sunday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
        holiday: Yup.string()
          .max(15)
          .typeError('must be less than 15 characters')
          .required('Required'),
      })
    ),
  });

  return (
    <Formik
      enableReinitialize={true}
      validateOnMount={isEdit}
      initialTouched={{
        locationId: isEdit,
        name: isEdit,
        marketName: isEdit,
        marketId: isEdit,
        address: isEdit,
        city: isEdit,
        state: isEdit,
        stateId: isEdit,
        stateName: isEdit,
        zip: isEdit,
        accountsPayableId: isEdit,
        latitude: isEdit,
        longitude: isEdit,
        openDate: isEdit,
        closeDate: isEdit,
        isVisible: isEdit,
        legalEntityId: isEdit,
        legalEntityName: isEdit,
      }}
      initialValues={{
        locationType:
          location?.locationType === 'GeneralPractice'
            ? LocationType.GeneralPractice
            : location?.locationType === 'UrgentVet'
            ? LocationType.UrgentVet
            : '',
        locationId: location?.locationId ?? '',
        name: location?.name ?? '',
        marketName: location?.marketName ?? '',
        marketId: location?.marketId ?? '',
        address: location?.address.street ?? '',
        city: location?.address?.city ?? '',
        state: location?.address?.state ?? '',
        stateId: location?.address?.stateId ?? '',
        stateName: location?.address?.stateName ?? '',
        zip: location?.address?.zip ?? '',
        accountsPayableId: location?.accountsPayableId ?? '',
        latitude: location?.address?.latitude ?? '',
        longitude: location?.address?.longitude ?? '',
        locationShifts: location?.locationShifts ?? [],
        openDate: location?.openDate ?? '',
        closeDate: location?.closeDate ?? '',
        isVisible: location?.isVisible ?? true,
        legalEntityId: location?.legalEntityId ?? '',
        legalEntityName: location?.legalEntityName ?? '',
      }}
      validationSchema={Schema}
      onSubmit={async values => {
        const selectedState = states.find(state => state.value === values.stateId);
        const data = {
          location: {
            ...location,
            locationType: values.locationType,
            locationId: !!parseInt(values.locationId as string) ? values.locationId : 0,
            name: values.name,
            marketName: values.marketName,
            accountsPayableId: values.accountsPayableId,
            openDate: values.openDate === '' ? null : values.openDate,
            closeDate: values.closeDate === '' ? null : values.closeDate,
            isVisible: values.isVisible,
            address: {
              addressId: location?.address?.addressId ?? 0,
              street: values.address,
              city: values.city,
              state: selectedState?.shorthand,
              stateName: selectedState?.description ?? '',
              stateId: selectedState?.value ?? '',
              zip: values.zip,
              latitude: Number(values.latitude) ?? 0,
              longitude: Number(values.longitude) ?? 0,
            },
            marketId: Number(values.marketId),
            locationShifts: values.locationShifts.map(shift =>
              shift.locationShiftId < 0 ? { ...shift, locationShiftId: 0 } : shift
            ),
            legalEntityId: values?.legalEntityId,
            legalEntityName: values?.legalEntityName,
          },
        };

        if (data?.location?.openDate !== null && !isValidDate(String(data?.location?.openDate))) {
          return enqueueSnackbar(`Please enter a valid date`, {
            variant: 'error',
          });
        }

        if (data?.location?.closeDate !== null && !isValidDate(String(data?.location?.closeDate))) {
          return enqueueSnackbar(`Please enter a valid date`, {
            variant: 'error',
          });
        }

        try {
          if (isEdit) {
            await updateLocation(data);
            enqueueSnackbar(`Location updated!`, {
              variant: 'success',
            });
            history.push('/locations');
          } else {
            const locationId = await createLocation(data);
            enqueueSnackbar(`Location created!`, {
              variant: 'success',
            });
            history.push(`/locations/${locationId}`);
          }
        } catch (error: any) {
          const errorMessage = error?.response?.data?.Detail;

          if (!!isEdit) {
            enqueueSnackbar(
              errorMessage ||
                `Error updating a new Location, please make sure all dates are unique and try again.`,
              {
                variant: 'error',
              }
            );
          } else {
            enqueueSnackbar(
              errorMessage ||
                `Error creating a new Location, please make sure all dates are unique and try again.`,
              {
                variant: 'error',
              }
            );
          }
        }
      }}
    >
      {({
        isSubmitting,
        values,
        initialValues,
        setFieldValue,
        handleSubmit,
        dirty,
        isValid,
        handleBlur,
        errors,
        touched,
        validateForm,
      }) => {
        return (
          <Page
            title={isEdit ? 'Edit Location' : 'Add New Location'}
            additionalHeaderContent={
              <FormControlLabel
                control={
                  <Switch
                    disabled={canViewRoles}
                    checked={values.isVisible}
                    onChange={e => {
                      setFieldValue('isVisible', e.target.checked);
                    }}
                    inputProps={{ 'aria-label': 'controlled' }}
                  />
                }
                label="Visible on Map"
              />
            }
          >
            <Grid container spacing={3}>
              <Grid item xs={12}>
                <Form onSubmit={handleSubmit} autoComplete="none">
                  {(isSubmitting ||
                    isLoading ||
                    isMarketsLoading ||
                    areLocationTypesLoading ||
                    areLegalEntitiesLoading ||
                    areStatesLoading) && (
                    <Loader type="fullscreen" position="centered" title="Loading..." />
                  )}
                  <Grid container spacing={3}>
                    <Grid item xs={12} lg={6}>
                      <IconHeading
                        icon={LocationOnOutlinedIcon}
                        title="Location Details"
                        variant="h2"
                      />
                      <FormControl fullWidth required={true} variant="standard" size="small">
                        <InputLabel htmlFor="locationTypeLabel">Location Type</InputLabel>
                        <Select
                          fullWidth
                          name="locationType"
                          labelId="locationTypeLabel"
                          id="locationType"
                          disabled={areLocationTypesLoading || isEdit}
                          error={errors && errors.locationType ? true : false}
                          value={values.locationType}
                          onChange={e => {
                            setFieldValue('locationType', e.target.value);
                          }}
                        >
                          {locationTypes?.map((locationType: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index}`} value={locationType.value}>
                                {locationType.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.locationType && errors?.locationType && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>

                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Location Name"
                        name="name"
                        value={values.name}
                        onBlur={handleBlur}
                        disabled={canViewRoles}
                        size="small"
                        required
                        onChange={e => setFieldValue('name', e.target.value)}
                        error={touched.name && errors && errors.name ? true : false}
                        helperText={touched.name && errors && errors.name}
                      />

                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Address"
                        name="address"
                        value={values.address}
                        onBlur={handleBlur}
                        disabled={canViewRoles}
                        size="small"
                        required
                        onChange={e => setFieldValue('address', e.target.value)}
                        error={touched.address && errors && errors.address ? true : false}
                        helperText={touched.address && errors && errors.address}
                      />

                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="City"
                        name="city"
                        value={values.city}
                        onBlur={handleBlur}
                        disabled={canViewRoles}
                        size="small"
                        required
                        onChange={e => setFieldValue('city', e.target.value)}
                        error={touched.city && errors && errors.city ? true : false}
                        helperText={touched.city && errors && errors.city}
                      />

                      <FormControl fullWidth required={true} variant="standard" size="small">
                        <InputLabel htmlFor="stateLabel">State</InputLabel>
                        <Select
                          fullWidth
                          name="state"
                          labelId="stateLabel"
                          id="state"
                          disabled={areStatesLoading || canViewRoles}
                          error={touched.stateId && errors && errors.stateId ? true : false}
                          value={values.stateId}
                          onChange={e => {
                            const selectedState = states.find(
                              state => e.target.value === state.value
                            );
                            setFieldValue('stateId', e.target.value);
                            setFieldValue('state', selectedState?.shorthand ?? '');
                            setFieldValue('stateName', selectedState?.description ?? '');
                            validateForm('state');
                          }}
                        >
                          {states?.map((state: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index}`} value={state.value}>
                                {state.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.state && errors?.state && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Zip"
                        name="zip"
                        value={values.zip}
                        onBlur={handleBlur}
                        disabled={canViewRoles}
                        size="small"
                        required
                        onChange={e => {
                          const re = /^[0-9\b]+$/;
                          // if value is not blank, then test the regex
                          if (e.target.value === '' || re.test(e.target.value)) {
                            setFieldValue('zip', e.target.value);
                          }
                        }}
                        error={touched.zip && errors && errors.zip ? true : false}
                        helperText={touched.zip && errors && errors.zip}
                      />
                      <TextField
                        fullWidth
                        variant="standard"
                        autoComplete="nope"
                        label="Accounting Location ID"
                        name="accountsPayableId"
                        value={values.accountsPayableId}
                        onBlur={handleBlur}
                        disabled={canViewRoles}
                        size="small"
                        onChange={e => {
                          setFieldValue('accountsPayableId', e.target.value);
                        }}
                        error={
                          touched.accountsPayableId && errors && errors.accountsPayableId
                            ? true
                            : false
                        }
                        helperText={touched.accountsPayableId && errors && errors.accountsPayableId}
                      />
                      <FormControl fullWidth required={true} variant="standard" size="small">
                        <InputLabel htmlFor="legalEntityLabel">Legal Entity</InputLabel>
                        <Select
                          fullWidth
                          name="legalEntity"
                          labelId="legalEntityLabel"
                          id="legalEntity"
                          disabled={areLegalEntitiesLoading || canViewRoles}
                          error={errors && errors.legalEntityId ? true : false}
                          value={values.legalEntityId}
                          onChange={e => {
                            setFieldValue('legalEntityId', e.target.value);
                            setFieldValue(
                              'legalEntityName',
                              legalEntities.find(
                                legalEntity => legalEntity.value === e.target.value
                              )?.description ?? ''
                            );
                          }}
                        >
                          {legalEntities?.map((legalEntities: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index}`} value={legalEntities.value}>
                                {legalEntities.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.legalEntityId && errors?.legalEntityId && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} lg={6}>
                      <IconHeading
                        icon={WorkspacesIcon}
                        title="Location Classifications"
                        variant="h2"
                      />
                      <FormControl fullWidth required={true} variant="standard" size="small">
                        <InputLabel htmlFor="marketLabel">Market</InputLabel>
                        <Select
                          fullWidth
                          name="market"
                          labelId="marketLabel"
                          id="market"
                          disabled={isMarketsLoading || canViewRoles}
                          error={errors && errors.marketId ? true : false}
                          value={values.marketId}
                          onChange={e => {
                            setFieldValue('marketId', e.target.value as number);
                            setFieldValue(
                              'marketName',
                              markets.find(market => market.value === e.target.value)
                                ?.description ?? ''
                            );
                          }}
                        >
                          {markets?.map((market: IDropdownResponse, index) => {
                            return (
                              <MenuItem key={`${index}`} value={market.value}>
                                {market.description}
                              </MenuItem>
                            );
                          })}
                        </Select>
                        {touched?.marketId && errors?.marketId && (
                          <FormHelperText error>Required</FormHelperText>
                        )}
                      </FormControl>
                      <FormControl className={classes.openDate} fullWidth variant="outlined">
                        {!isMobile ? (
                          <DesktopDatePicker
                            disablePast
                            label={'Open Date'}
                            inputFormat="MM/dd/yyyy"
                            value={values.openDate}
                            disabled={canViewRoles}
                            onChange={(e: any) => {
                              if (e === null) {
                                e = '';
                              }
                              setFieldValue('openDate', e);
                            }}
                            renderInput={(params: any) => {
                              return (
                                <TextField
                                  size="small"
                                  variant="standard"
                                  {...params}
                                  error={
                                    touched.openDate && errors && errors.openDate ? true : false
                                  }
                                  helperText={touched.openDate && errors && errors.openDate}
                                />
                              );
                            }}
                          />
                        ) : (
                          <MobileDatePicker
                            disablePast
                            label={'Open Date'}
                            inputFormat="MM/dd/yyyy"
                            value={values.openDate}
                            disabled={canViewRoles}
                            onChange={(e: any) => {
                              if (e === null) {
                                e = '';
                              }
                              setFieldValue('openDate', e);
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                variant="standard"
                                size="small"
                                {...params}
                                error={touched.openDate && errors && errors.openDate ? true : false}
                                helperText={touched.openDate && errors && errors.openDate}
                              />
                            )}
                          />
                        )}
                      </FormControl>
                      <FormControl className={classes.closeDate} fullWidth variant="outlined">
                        {!isMobile ? (
                          <DesktopDatePicker
                            disablePast
                            label={'Close Date'}
                            inputFormat="MM/dd/yyyy"
                            value={values.closeDate}
                            disabled={canViewRoles}
                            onChange={(e: any) => {
                              if (e === null) {
                                e = '';
                              }
                              setFieldValue('closeDate', e);
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                size="small"
                                variant="standard"
                                {...params}
                                error={
                                  touched.closeDate && errors && errors.closeDate ? true : false
                                }
                                helperText={touched.closeDate && errors && errors.closeDate}
                              />
                            )}
                          />
                        ) : (
                          <MobileDatePicker
                            disablePast
                            label={'Close Date'}
                            inputFormat="MM/dd/yyyy"
                            value={values.closeDate}
                            disabled={canViewRoles}
                            onChange={(e: any) => {
                              if (e === null) {
                                e = '';
                              }
                              setFieldValue('date', e);
                            }}
                            renderInput={(params: any) => (
                              <TextField
                                variant="standard"
                                size="small"
                                {...params}
                                error={
                                  touched.closeDate && errors && errors.closeDate ? true : false
                                }
                                helperText={touched.closeDate && errors && errors.closeDate}
                              />
                            )}
                          />
                        )}
                      </FormControl>

                      {location?.locationType === 'UrgentVet' && (
                        <IncentiveRates
                          isLoading={isLoading}
                          locationId={locationId}
                          canEdit={canEdit}
                        />
                      )}
                    </Grid>
                  </Grid>
                  {location?.locationType === 'UrgentVet' && shifts && (
                    <Box>
                      <LocationShifts
                        key={'locationShifts'}
                        shifts={shifts}
                        isLoading={isLoading}
                        setFieldValue={setFieldValue}
                        errors={errors}
                        validateForm={validateForm}
                        values={values}
                      />
                    </Box>
                  )}
                  {location?.locationType === 'GeneralPractice' && (
                    <ConfiguredShifts isLoading={isLoading} locationId={locationId} />
                  )}
                  <Box marginTop="1rem" marginBottom="1rem">
                    <Button
                      className={classes.button}
                      disabled={!dirty || isSubmitting || !isValid}
                      type="submit"
                      startIcon={<Edit />}
                      variant="contained"
                      color="primary"
                    >
                      Save
                    </Button>
                    <Button
                      className={classes.button}
                      type="button"
                      variant="contained"
                      color="inherit"
                      disabled={isSubmitting}
                      startIcon={<Close />}
                      onClick={() => {
                        //@ts-ignore
                        if (!deepEqual(initialValues, values)) {
                          const result = window.confirm(
                            'You have unsaved changes, are you sure you want to exit?'
                          );
                          if (result) {
                            history.push('/locations');
                          } else {
                            return;
                          }
                        } else {
                          history.push('/locations');
                        }
                      }}
                    >
                      Cancel
                    </Button>
                  </Box>
                </Form>
              </Grid>
            </Grid>
          </Page>
        );
      }}
    </Formik>
  );
};
const useStyles = makeStyles<Theme>((theme: Theme) => ({
  button: {
    '&:not(:first-of-type)': {
      marginLeft: theme.spacing(1),
    },
  },
  openDate: {
    marginTop: 0,
  },
  closeDate: {
    marginTop: -8,
  },
}));
